import React, { FC } from "react";
import logo from "./../../../../../../assets/img/header/logo.png";
import style from "./style.module.css";

interface IProps {
  isShowSideBar: boolean;
}

export const HeaderSideBar: FC<IProps> = ({ isShowSideBar }) => {
  return (
    <div className={style.headerSideBar}>
      <div className={style.wrapperLogo}>
        <img
          src={logo}
          className={isShowSideBar ? style.logoExpanded : style.logoCollapsed}
          alt=""
        />
      </div>
      <p className={isShowSideBar ? style.titleExpanded : style.titleCollapsed}>
        JETVAULT
      </p>
    </div>
  );
};
