import React from 'react'
import style from './style.module.css'

interface IProps  {
  label?: string;
  error?: string;
  keyField: string
  type?: string;
  placeholder?: string;
  register: any;
  children?: any;
  modeDate?: 'birth' | 'cooperation';
  modeColor?: 'black' 
};

export const InputBox = ({ label, error, keyField, placeholder, type, register, children, modeDate, modeColor}: IProps) => {
  const current = new Date().toISOString().split("T")[0]
  
  return (
    <div className={modeColor === 'black' ?  style.formGroupColor : style.formGroup}>
      {label ? <label className={style.inputLabel}>{label}</label> : null}
      <input
        type={type}
        max={modeDate === 'birth' ? current : null}
        placeholder={placeholder}
        {...register(keyField)}
        className={`${style.formControl} ${error ? `${style.isInvalid}` : ''}
        `}
      />
      {children}
      <div className={`${style.line} ${error ? `${style.isInvalidLine}` : ''}`}></div>
      <div className={style.invalidFeedback}>{error}</div>
    </div>
  )
}
