import React, { useState } from 'react'
import style from './../registration-page/styles.module.css'
import user from '../../../../assets/img/auth/user.svg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaRegistration } from '../../valid-schema/validation-schema-registration'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '@/core/components/buttons/primary-button'
import { SecondaryButton } from '@/core/components/buttons/secondary-button'
import { InputBox } from '@/core/components/form/input-box'
import { message, Select } from 'antd'
import { authService } from '@/services/domain/auth.services'
import { UserRegistrationForm } from '../../interface/registration-form.interface'
import { PasswordToggle } from '@/core/components/form/password-toggle'
import { AccountTags } from '@/core/components/account-tags'
import { SelectControl } from '@/core/components/form/select-contol'
import { userPositionConfig } from '@/modules/users/config/user-position.config'
import { optionsUserPosition } from '@/modules/users/config/options'
import { IRegistrationPayload } from "@/api/auth/interfaces";
import _ from "lodash";


const { Option } = Select;

export const RegistrationPage = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [passwordShown, setPasswordShown] = useState<boolean>(false);
	const [tags, setTags] = useState<string[]>([]);

	const errorError = (error: string) => {
		messageApi.open({
			type: "error",
			content: `Error: ${error}`,
		});
	};

	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<UserRegistrationForm>({
		resolver: yupResolver(validationSchemaRegistration),
		mode: "onChange",
		defaultValues: {
			cooperationStartDate: new Date().toISOString().split("T")[0],
			birthDate: "2000-01-01",
			position: userPositionConfig.d,
		},
	});

	const onSubmit = async (data: UserRegistrationForm) => {
		const technologies = tags.length ? tags : [];
		try {
      const prepareSave: IRegistrationPayload = {
        ..._.omit(data, ["position"]),
        technologies,
        positions: [data.position],
      };
			await authService.registration(prepareSave);
		} catch (error) {
			if (error.key === "user_already_exists") {
				errorError("There is already a user with such created email");
			} else {
				errorError(error.message);
			}
			console.log("Error");
		}
	};

	const renderOptions = (options: any) => {
		return options.map((it: any) => {
			return (
				<Option className={style.selectOptions} key={it.value} label={it.label}>
					{it.label}
				</Option>
			);
		});
	};

	return (
		<div className={style.container}>
			{contextHolder}
			<div className={style.content}>
				<div className={style.iconContainer}>
					<img src={user} alt="" />
				</div>
				<h2 className={style.header}>Registration</h2>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.login}>
						<div className={style.fullNameContainer}>
							<InputBox
								register={register}
								label="First Name"
								placeholder="Enter First Name"
								error={errors.name?.message}
								keyField={"name"}
							/>
							<InputBox
								register={register}
								label="Last Name"
								placeholder="Enter Last Name"
								error={errors.lastName?.message}
								keyField={"lastName"}
							/>
						</div>
						<div className={style.emailBirthdayContainer}>
							<InputBox
								register={register}
								label="User Email"
								placeholder="Enter Email"
								error={errors.email?.message}
								keyField={"email"}
							/>
							<InputBox
								register={register}
								type="date"
								modeDate="birth"
								label="Date of Birth"
								error={errors.birthDate?.message}
								keyField={"birthDate"}
							/>
						</div>
						<div className={style.passwordCooperationContainer}>
							<InputBox
								register={register}
								label="Password"
								placeholder="Enter Password"
								error={errors.password?.message}
								keyField={"password"}
								type={passwordShown ? "text" : "password"}>
								<PasswordToggle
									passwordShown={passwordShown}
									setPasswordShown={setPasswordShown}
								/>
							</InputBox>

							<InputBox
								register={register}
								type="date"
								modeDate="cooperation"
								label="Date of Start Cooperation"
								error={errors.cooperationStartDate?.message}
								keyField={"cooperationStartDate"}
							/>
						</div>

						<div className={style.technologiesContainer}>
							<div>
								<div className={style.technologiesText}>
									Enter your technology:
								</div>
								<div className={style.accountTagsContainer}>
									<AccountTags tagLabel="" tags={tags} setTags={setTags} />
								</div>
							</div>

							<div className={style.accountPositionContainer}>
								<div className={style.technologiesText}>
									Enter your Position:
								</div>
								<SelectControl
									size="middle"
									renderOptions={() => renderOptions(optionsUserPosition)}
									control={control}
									register={register}
									name={"position"}
								/>
							</div>
						</div>
					</div>
					<div className={style.buttonGroup}>
						<PrimaryButton type="submit" text="Sign in"></PrimaryButton>
						<SecondaryButton
							onClick={() => {
								reset();
								setTags([]);
							}}
							type="button"
							text="Cancel"></SecondaryButton>
					</div>
					<Link className={style.link} to={"/login"}>
						Log in →
					</Link>
				</form>
			</div>
		</div>
	);
};
