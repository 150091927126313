import { IFile } from "@/typing/interfaces";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import styles from "./index.module.css";
import { getOrDef, toBase64 } from "@/modules/projects/helpers/images.helpers";

interface IProps {
	onChange?: (photo: any) => void;
	icon?: JSX.Element;
	value?: IFile | string;
	style?: CSSProperties;
	onSelectFile?: (file: any) => void;
}

export const UploadImage: FC<IProps> = ({ onChange, icon, value, style }) => {
	const [image, setImage] = useState<any>(null);

	const memoPreviewUrl = useMemo(() => {
		return getOrDef(image, value);
	}, [image, value]);

	const onChangeHandler = async (event: any) => {
		const file: any = event.target.files[0];

		setImage(await toBase64(file));
		onChange(file);
	};

	useEffect(() => {
		if (!value) {
			setImage(value);
		}
	}, [value]);

	return (
		<>
			<div style={{ ...style }}>
				<label htmlFor="upload">
					<div className={`${styles.showUpload} ${styles.wrapper}`}>
						{memoPreviewUrl ? (
							<img
								src={memoPreviewUrl}
								alt="coverPhoto"
								style={{
									borderRadius: 142,
									width: "142px",
									height: "142px",
									objectFit: "cover",
								}}
							/>
						) : (
							<>
								<h5 className={styles.title}>Cover photo</h5>
							</>
						)}
					</div>
				</label>
				<input
					type="file"
					id="upload"
					style={{ display: "none" }}
					onChange={onChangeHandler}
					accept="image/png, image/jpeg"
				/>
				<br />
			</div>
		</>
	);
};
