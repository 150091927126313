import React, { useEffect, useState } from "react";
import { WikiList } from "../../components/wiki-list";
import { WikiDetail } from "../../components/wiki-detail";
import { useEventsListener, usePaginationList } from "@/core/hooks";
import { getWikiDetailRequest, getWikiListRequest } from "@/api/wiki";
import { useParams } from "react-router-dom";
import { IWiki } from "@/typing";
import { ShareWikiButton } from "../../components/share-wiiki";

export const WikiPage = () => {
	const { id: projectId } = useParams();

	const [idWiki, setWikiId] = useState<number>(null);
	const [openDetail, setOpenDetail] = useState<boolean>(true);
	const [wiki, setWiki] = useState<IWiki>(null);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	useEventsListener(
    "openDrawer",
    (data) => {
      setOpenDrawer(true);
    },
    []
  );

  useEventsListener(
    "openWikiTab",
    (data) => {
      setOpenDetail(true);
    },
    []
  );

	const list = usePaginationList<IWiki>({
		fetchItems: (params) => getWikiListRequest({ ...params, projectId }),
		needInit: true,
		limit: 10,
		clearWhenReload: false,
		loadParams: {
			sortField: "id",
			sort: "DESC",
		},
	});

	const loadWiki = async (id: number) => {
		try {
			const { data } = await getWikiDetailRequest(id);
			setWiki(data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		loadWiki(idWiki);
	}, [idWiki]);

	useEffect(() => {
		list.setLoadParams({ projectId: projectId });
	}, [projectId]);

	useEffect(() => {
		list.resetFlatList();
	}, []);

	const renderContent = () => {
		return (
			<>
				{!openDetail && <ShareWikiButton wikiId={idWiki} />}
				{openDetail ? (
					<WikiList
						items={list.items}
						isLoading={list.isLoading}
						onResetWiki={() => list.resetFlatList()}
						onloadPage={(page) => list.loadPage(page)}
						onSetLoadParams={(size) => list.setLoadParams({ limit: size })}
						page={list.loadParams.page}
						count={list.loadParams.count}
						limit={list.loadParams.limit}
						onOpenDetail={() => setOpenDetail((prev) => (prev = false))}
						onWikiId={(id) => setWikiId((prev) => (prev = id))}
						isOpenDrawer={openDrawer}
						onOpenDrawer={() => setOpenDrawer((prev) => (prev = false))}
					/>
				) : (
					<WikiDetail
						onLoadWiki={(id) => loadWiki(id)}
						onResetWiki={list.resetFlatList}
						wiki={wiki}
						isOpenDrawer={openDrawer}
						onOpenDrawer={() => setOpenDrawer((prev) => (prev = false))}
					/>
				)}
			</>
		);
	};
	return renderContent();
};
