import React, { useEffect, useState } from 'react';
import style from './styles.module.css';
import { PrimaryButton } from '@/core/components/buttons/primary-button';
import { PageWrapper } from '@/core/components/page/page-wrapper';
import { ProjectCard } from '../../components/project-card';
import { ProjectForm } from '../../components/project-form/index';
import { ConfigProvider, message, Pagination } from 'antd';
import {
  storeProjectReq,
  getProjectsReq,
  deleteProjectReq,
  patchProjectReq,
} from '@/api/projects/index';
import { EmptyList } from '@/core/components/empty-list';
import { usePaginationList } from '@/core/hooks';
import _ from 'lodash';
import { IProject } from '@/typing/interfaces/domain/project.interface';
import { sectAccount } from '@/store/account/selectors';
import { useSelector } from 'react-redux';
import { UserRole } from '@/typing/enums/users-role.enum';
import { ConfirmPopup } from '@/core/components/pop-up/confirm-popup';
import { PaginationArrow, SearchInput } from "@/core/components";
import { projectService } from "@/services/domain";

export const ProjectsPage: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [popupDelete, setPopupDelete] = useState({
    isShow: false,
    id: null,
  });

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [dataEdit, setDataEdit] = useState<IProject>(null);

  const [modeEditOrCreate, setModeEditOrCreate] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");

  const { data: account } = useSelector(sectAccount);

  const list = usePaginationList<IProject>({
    fetchItems: getProjectsReq,
    needInit: true,
    limit: 10,
    clearWhenReload: false,
    loadParams: {
      sortField: "id",
      sort: "DESC",
    },
  });

  useEffect(() => {
    list.setLoadParams({
      searchString: searchValue,
    });
  }, [searchValue]);

  const errorError = (error: string) => {
    messageApi.open({
      type: "error",
      content: `Error: ${error}`,
    });
  };

  const onRemoveProject = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const projectId = list.items.find(
        (el: IProject) => el.id === popupDelete.id
      );
      await deleteProjectReq(projectId);
      list.setLoadParams({});
    } catch (error) {
      errorError(error.message);
    }

    setPopupDelete({
      isShow: false,
      id: null,
    });
  };

  const preparationEditingProject = (
    e: React.MouseEvent<HTMLButtonElement>,
    info: IProject
  ) => {
    e.preventDefault();
    setOpenDrawer(true);

    setDataEdit(info);
    setModeEditOrCreate("edit");
  };

  const openPopupDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    e.preventDefault();
    setPopupDelete({ isShow: true, id: id });
  };

  const onClickAddProject = () => {
    setOpenDrawer(true);
    setModeEditOrCreate("create");
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDataEdit(null);
  };

  const createProject = async (newData: IProject, id: any) => {
    try {
      await projectService.createProject(newData);
      setTimeout(() => {
        list.resetFlatList();
      }, 200);
      list.setLoadParams({});
    } catch (error) {
      errorError(error.message);
    }
  };

  const editProject = async (newData: IProject) => {
    try {
      await projectService.updateProject(newData);
      setTimeout(() => {
        list.resetFlatList();
      }, 200);
      list.setLoadParams({});
    } catch (error) {
      errorError(error.message);
    }
  };

  return (
    <PageWrapper
      searchValue={searchValue}
      onChangeSearchValue={(val) => setSearchValue((prev) => (prev = val))}
      textPlaceholder={"Search project"}
      blockSearch={true}
    >
      {contextHolder}
      <div className={style.wrapper}>
        {!_.isEmpty(list.items) && !list.isLoading ? (
          <div className={style.box}>
            <div className={style.inner}>
              {list.items.map((el: IProject) => {
                return (
                  <ProjectCard
                    accountRole={account.role}
                    info={el}
                    key={el.id}
                    removeProject={openPopupDelete}
                    editProject={preparationEditingProject}
                  />
                );
              })}
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#686868",
                },
              }}
            >
              <div className={style.paginationWrapper}>
                <div>
                  {account.role !== UserRole.User ? (
                    <div>
                      <PrimaryButton
                        individualStyle={{ width: 213 }}
                        type="button"
                        text="Add new project"
                        onClick={onClickAddProject}
                      />

                      <ProjectForm
                        titleDrawer={modeEditOrCreate}
                        openDrawer={openDrawer}
                        modeForm={modeEditOrCreate}
                        onClose={onCloseDrawer}
                        onSaveProject={createProject}
                        onSaveEdit={editProject}
                        dataEdit={dataEdit}
                      />
                    </div>
                  ) : null}
                </div>
                <Pagination
                  current={list.loadParams.page}
                  total={list.loadParams.count}
                  pageSize={list.loadParams.limit}
                  onChange={(page) => list.loadPage(page)}
                  onShowSizeChange={(_, size: number) =>
                    list.setLoadParams({ limit: size })
                  }
                  className={style.pagination}
                  showSizeChanger={true}
                  itemRender={PaginationArrow}
                />
              </div>
            </ConfigProvider>
          </div>
        ) : (
          <EmptyList
            conditionLoader={!list.isLoading}
            errorText={"list is empty"}
          />
        )}
        <ConfirmPopup
          title={`Are you sure
					Delete this user?`}
          popupActive={popupDelete}
          onClose={setPopupDelete}
          onConfirm={onRemoveProject}
        />
      </div>
    </PageWrapper>
  );
};

