import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import style from './styles.module.css'
import { message, Space, Tag } from 'antd'
import { useSelector } from 'react-redux'
import { sectAccount } from '@/store/account/selectors'
import { accountService } from '@/services/domain/account.services'
import { IUser, UserProject } from '@/typing'
import { Loader, PageWrapper, SecondaryButton } from '@/core/components'
import { AccountCard } from '@/core/components/account-card'
import { AccountForm } from '@/modules/account/components'
import { categoriesConfig } from '@/modules/account/config'
import { useParams } from 'react-router-dom'
import { getUsersProfileReq, patchUsersReq } from '@/api/users'

export const UserProfilePage: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [dataEdit, setDataEdit] = useState<IUser>(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [account, setAccount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    getUserProfile()
  }, [id])

  const [tags, setTags] = useState(account?.technologies)

  const getUserProfile = async () => {
    try {
      setIsLoading(true)
      const data = await getUsersProfileReq(+id)
      setAccount(data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const errorError = (error: string) => {
    messageApi.open({
      type: 'error',
      content: `Error: ${error}`,
    })
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
    setDataEdit(null)
  }

  const editUser = async (newData: IUser) => {
    try {

      const prepareSave = _.omit(newData, ["avatarUrl"]);

      await patchUsersReq(prepareSave);
      await getUserProfile();
    } catch (error) {
      errorError('No changes sent')
    }
  }

  const preparationEditingUser = () => {
    setOpenDrawer(true)
    setDataEdit(account)
  }


  return (
    <PageWrapper blockSearch={false}>
      {contextHolder}
      {!_.isEmpty(account) && !isLoading ? (
        <div className={style.wrapper}>
          <h2 className={style.pageTitle}>Profile</h2>
          <AccountCard
            mode="profile"
            preparationEditingAccount={preparationEditingUser}
            account={account}
          />
          <AccountForm
            mode="profile"
            openDrawer={openDrawer}
            onClose={onCloseDrawer}
            onSaveEdit={editUser}
            dataEdit={dataEdit}
            tags={tags}
            setTags={setTags} 
            titleDrawerText={'Edit information about user'}     />
        </div>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  )
}
