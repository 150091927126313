import { getWikiDetailRequest } from "@/api/wiki";
import { IWiki } from "@/typing";
import React, { useEffect, useState } from "react";
import { WikiDetail } from "../../components/wiki-detail";
import { useParams } from "react-router-dom";

export const PublicWikiPage = () => {
  const params: any = useParams();
  const [idWiki, setWikiId] = useState<number>(null);
  const [wiki, setWiki] = useState<IWiki>(null);

  const loadWiki = async (id: number) => {
    try {
      const { data } = await getWikiDetailRequest(id);
      setWiki(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadWiki(params?.id);
  }, [params?.id]);

  return (
    <div style={{ padding: "20px" }}>
      <WikiDetail
        onLoadWiki={(id) => loadWiki(id)}
        onResetWiki={() => {}}
        wiki={wiki}
        isOpenDrawer={false}
        onOpenDrawer={() => {}}
      />
    </div>
  );
};
