import React, { FC, useEffect, useState } from "react";
import { Loader } from "@/core/components/loader";
import style from "./style.module.css";
import { Empty } from "antd";
import { ReactComponent as BookIcon } from "./../../../../assets/img/project/book.svg";
import { PrimaryButton } from "@/core/components";
import { useNavigate } from "react-router-dom";
import { FormDrawerWiki } from "../../smart-components";

interface IProps {
	conditionLoader: boolean;
	projectId: string;
	onResetWiki: () => void;
}

export const EmptyWiki: FC<IProps> = ({
	projectId,
	conditionLoader,
	onResetWiki,
}) => {
	const navigate = useNavigate();

	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	useEffect(() => {
		onResetWiki();
	}, [openDrawer]);

	return !conditionLoader ? (
		<Loader />
	) : (
		<Empty
			className={style.emptyWiki}
			image={<BookIcon className={style.emptyIcon} />}
			imageStyle={{ height: 90, width: 90 }}
			description={
				<>
					<h4 className={style.emptyHeader}>Welcome to the Wiki.</h4>
					<span className={style.emptyDescription}>
						A wiki allows you to write and share documentation with
						contributors.
					</span>
				</>
			}>
			<PrimaryButton
				text="Add Wiki"
				type="button"
				individualStyle={{ width: 213, height: 40 }}
				onClick={() => setOpenDrawer((prev) => (prev = true))}
			/>
			<FormDrawerWiki
				isOpen={openDrawer}
				onPressClose={() => setOpenDrawer((prev) => (prev = false))}
			/>
		</Empty>
	);
};
