import {
	BaseDrawer,
	EditorMarkdown,
	PrimaryButton,
	SecondaryButton,
	Textarea,
} from "@/core/components";
import { InputFormBox } from "@/core/components/form/input-form-box";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useFormWiki } from "../../hooks";
import styles from "./style.module.css";
import { IWiki } from "@/typing";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";

interface IProps {
	isOpen?: boolean;
	onPressClose?: () => void;
	existWiki?: IWiki;
	onSuccessEdit?: (id: number) => void;
	onResetWiki?: () => void;
}

export const FormDrawerWiki: FC<IProps> = ({
	onPressClose,
	onResetWiki,
	isOpen,
	existWiki,
	onSuccessEdit,
}) => {
	const { id: projectId } = useParams();

	const wiki: IWiki = useMemo(() => {
		return existWiki;
	}, [existWiki]);

	const { register, control, errors, onSubmit, reset, setFocus, isValid } =
		useFormWiki(Number(projectId), onPressClose, wiki);

	useEffect(() => {
		reset({
			title: "",
			content: "",
		});
		isOpen &&
			setTimeout(() => {
				setFocus("title");
			}, 500);
	}, [isOpen]);

	return (
		<BaseDrawer
			titleDrawerText={existWiki ? "Edit Wiki" : "Add Wiki"}
			openDrawer={isOpen}
			drawerWidth={"70%"}
			onCloseDrawer={() => onPressClose()}>
			<InputFormBox
				label="Title"
				keyField={"title"}
				placeholder="Write title of page"
				register={register}
				styles={{ marginBottom: 22 }}
				error={errors.title?.message}
			/>

			<Controller
				name="content"
				control={control}
				render={({ field: { onChange, value } }) => (
					<>
						<div className={styles.inputLabel}>Description</div>
						<div style={{ marginBottom: 50, height: 500 }}>
							<EditorMarkdown onChange={onChange} value={value} />
							<span className={styles.errorMark}>{errors.title?.message}</span>
						</div>
					</>
				)}
			/>

			<div className={styles.wrapperButtons}>
				<SecondaryButton
					onClick={() => {
						onPressClose();
						reset({ title: "", content: "" });
					}}
					individualStyle={{ width: 213, marginRight: 20 }}
					text={"Cancel"}
				/>
				<PrimaryButton
					onClick={() => {
						onSubmit();
						onResetWiki && onResetWiki();
						wiki &&
							setTimeout(() => {
								onSuccessEdit(wiki?.id);
							}, 500);
					}}
					individualStyle={{ width: 213 }}
					text={existWiki ? "Save" : "Create"}
					disabled={!isValid}
				/>
			</div>
		</BaseDrawer>
	);
};
