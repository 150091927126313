import {
  loginRoute,
  registrationRoute,
  passwordChange,
} from "@/modules/auth/config";
import { LogInPage, RegistrationPage } from "@/modules/auth/pages";
import { PasswordChangePage } from "@/modules/auth/pages/password-change-page";
import React from "react";
import { Navigate } from "react-router-dom";

export const routersAuth = [
  {
    path: loginRoute,
    element: <LogInPage />,
  },
  {
    path: registrationRoute,
    element: <RegistrationPage />,
  },
  {
    path: passwordChange,
    element: <PasswordChangePage />,
  },
  {
    path: "*",
    element: <Navigate to={loginRoute} />,
  },
];
