import { IMAGE_FILE_TYPES } from "@/services/system/file.service";

export const getFileType = (fileName: string) => {
  const splitName = fileName.split(".");
  return splitName[splitName.length - 1];
};

export const geTypeFileContent = (fileName: string) => {
  const typefile = getFileType(fileName);
  return IMAGE_FILE_TYPES.includes(typefile) ? "image" : "file";
};

export const shortFileName = (fileName: string) => {
  const splitName = fileName.split(".");
  return splitName[0].substring(0, 10) + "." + splitName[splitName.length - 1];
};
