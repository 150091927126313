import * as Yup from 'yup'

export const validationSchemaTesting= Yup.object().shape({
    name:  Yup.string().required('Title is required').trim(),
    preconditions: Yup.string().required('Preconditions is required').trim(),
    steps: Yup.string().required('Steps is required').trim(),
    expectedResult:  Yup.string().required('Expected Result is required').trim(),
    priorities: Yup.string().required('Priority Result is required').trim(),
    testCaseType: Yup.string().required('Type is required').trim(),
    automationType: Yup.string().required('Automation Type is required').trim()
})
