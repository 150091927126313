import { PrimaryButton } from '@/core/components'
import style from './styles.module.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ConfigProvider, Input } from 'antd'
import { isValidEmail } from '@/core/helpers'

interface IProps {
  onSubmit: () => void
  onChangeEmail: (value: string) => void
  email: string
}

export const ChangePasswordEnterEmail = ({ onSubmit, onChangeEmail, email }: IProps) => {
  const [emailError, setEmailError] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeEmail(e.target.value)
  }

  const handleSubmit = () => {
    if (!email) {
      setEmailError('Email is required')
      return
    }
    if (!isValidEmail(email)) {
      setEmailError('Invalid email address')
      return
    }
    
    onSubmit()
  }



  return (
    <div className={style.form}>
      <div className={style.login}>
        <div>
          <p className={style.inputName}>Enter email</p>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  colorPrimaryHover: '#606060',
                },
              },
            }}
          >
            <Input
              className={` ${emailError ? style.inputEmailError : style.inputEmail}`}
              required
              type="email"
              placeholder="Enter user email"
              value={email}
              onChange={handleInputChange}
            />
            {emailError ? <span className={style.errorText}>{emailError}</span> : null}
          </ConfigProvider>
        </div>

        <PrimaryButton text="Send Email" onClick={handleSubmit}></PrimaryButton>
      </div>

      <div className={style.buttonGroup}></div>
      <Link className={style.link} to={'/login'}>
        To Login →
      </Link>
    </div>
  )
}
