import React, { useEffect, useState } from "react";
import { IRecordContents } from "@/typing";
import { useEventsListener } from "@/core/hooks";
import { getRecordContentsReq } from "@/api/projects";
import { Modal } from "antd";
import { SearchInput } from "@/core/components";
import { RecordContentsList } from "../../components";
import { useParams } from "react-router-dom";

export const RecordContentsModalSmart = () => {
	const [isShowModal, setIsShowModal] = useState<boolean>(false);
	const [recordContents, setRecordContents] = useState<IRecordContents[]>();
	const [value, setValue] = useState<string>("");
	const { id } = useParams();

	const getRecordContents = async () => {
		try {
			const { data } = await getRecordContentsReq({
				searchString: value,
				projectId: Number(id),
			});

			console.log(data);

			setRecordContents((prev) => (prev = data));
		} catch (error) {
			console.log(error.data.message);
			setRecordContents((prev) => (prev = []));
		}
	};

	useEventsListener(
		"openBlockPopup",
		(data) => {
			setIsShowModal((prev) => (prev = data.isOpen));
		},
		[]
	);

	useEffect(() => {
		getRecordContents();
	}, [value, isShowModal]);

	return (
		<>
			{isShowModal && (
				<Modal
					title="Block contents search"
					centered
					open={isShowModal}
					footer={null}
					onCancel={() => {
						setIsShowModal((prev) => !prev);
						setValue("");
					}}
					width={1000}>
					<SearchInput value={value} onChange={(value) => setValue(value)} />
					<RecordContentsList recordContents={recordContents} />
				</Modal>
			)}
		</>
	);
};
