import React, { FC } from "react";
import style from "./style.module.css";
import { RecordContentsItem } from "../../atoms";
import { IRecordContents } from "@/typing";

interface IProps {
	recordContents: IRecordContents[];
}

export const RecordContentsList: FC<IProps> = ({ recordContents }) => {
	return (
		<div className={style.container}>
			<ul className={style.wrapperTitle}>
				{recordContents?.map((it) => {
					return <RecordContentsItem key={it.id} content={it.content} />;
				})}
			</ul>
		</div>
	);
};
