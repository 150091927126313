import { combinePaths } from '@/core/helpers/router.helpers'

export const wikiBaseRoute = ''
export const wikiDetailedRouterName = '/wiki/detailed/:id'
export const wikiEditorRouterName = ':id/wiki/editor'

export const wikiDetailedRouter = combinePaths(wikiBaseRoute, wikiDetailedRouterName)
export const wikiEditorRouter = combinePaths(wikiBaseRoute, wikiEditorRouterName)


export const publicBaseRoute = "public";

export const publicWikiRouteName = "wiki/:id";

export const publicWikiRoute = combinePaths(
  publicBaseRoute,
  publicWikiRouteName
);