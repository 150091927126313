import {
	postContentBlockReq,
	patchContentBlockReq,
	deleteContentBlockReq,
} from "@/api/projects";
import { getUsersReq } from "@/api/users";
import { ConfirmPopup } from "@/core/components/pop-up/confirm-popup";
import { usePaginationList } from "@/core/hooks";
import { sectAccount } from "@/store/account/selectors";
import { IUser } from "@/typing/interfaces/domain";
import { IProjectBlock } from "@/typing/interfaces/domain/project-block.interface";
import { message } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ContentBlock } from "../content-block";
import { ContentBlocksForm } from "../content-block-form";
import style from "./style.module.css";
import { EmptyList } from "@/core/components/empty-list";
import { ProjectTabsEnum } from "@/typing/enums/project-tabs.enum";
import { ContentBlockDetailed } from "../content-block-detailed";

interface IProps {
  reload?: () => void;
  projectBlocks?: IProjectBlock[];
  addBlock: boolean;
  setAddBlock: (data: boolean) => void;
  onChangeKey: (key: ProjectTabsEnum) => void;
  viewLookBlock: "list" | "detailed";
}

export const ContentBlockContainer: FC<IProps> = ({
  reload,
  projectBlocks,
  addBlock,
  setAddBlock,
  onChangeKey,
  viewLookBlock,
}) => {
  const { id: projectId } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [popupDelete, setPopupDelete] = useState({ isShow: false, id: null });
  const [dataEdit, setDataEdit] = useState<IProjectBlock>(null);
  const [modeEditOrCreate, setModeEditOrCreate] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const { data: account } = useSelector(sectAccount);

  const errorError = (error: string) => {
    messageApi.open({
      type: "error",
      content: `Error: ${error}`,
    });
  };

  const userList = usePaginationList<IUser>({
    fetchItems: getUsersReq,
    needInit: true,
    limit: 999,
    clearWhenReload: false,
    loadParams: {
      sortField: "id",
      sort: "DESC",
    },
  });

  const onRemoveBlock = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await deleteContentBlockReq(popupDelete.id);
      localStorage.removeItem("blockId");
      reload();
    } catch (error) {
      errorError(error.message);
    }
    setPopupDelete({
      isShow: false,
      id: null,
    });
  };

  const removeUserId = (arr: IUser[], id: number) => {
    const el = arr.findIndex((el: IUser) => el.id === id);
    if (el > -1) {
      arr.splice(el, 1);
    }
    return arr;
  };

  useEffect(() => {
    removeUserId(userList.items, account.id);
  }, [userList.items]);

  useEffect(() => {
    reload();
  }, [projectId]);

  useEffect(() => {
    if (addBlock) onClickAddBlock();
  }, [addBlock]);

  const preparationEditingBlock = (
    e: React.MouseEvent<HTMLButtonElement>,
    info: IProjectBlock
  ) => {
    e.preventDefault();
    setOpenDrawer(true);

    setDataEdit(info);
    setModeEditOrCreate("edit");
  };

  const createBlock = async (newData: IProjectBlock) => {
    try {
      await postContentBlockReq({ ...newData, projectId: Number(projectId) });
      setAddBlock(false);
      reload();
    } catch (error) {
      errorError(error.message);
    }
  };

  const editBlock = async (newData: IProjectBlock) => {
    try {
      await patchContentBlockReq(newData);
      reload();
    } catch (error) {
      errorError(error.response.data.message);
    }
  };

  const onClickAddBlock = () => {
    setOpenDrawer(true);
    setModeEditOrCreate("create");
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDataEdit(null);
    setAddBlock(false);
  };

  const openPopupDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    // e.preventDefault();
    setPopupDelete({ isShow: true, id: id });
  };
  const projectBlocksList = useMemo(() => {
    return (
      <>
        {projectBlocks.map((projectBlock: IProjectBlock) => {
          return (
            <ContentBlock
              info={projectBlock}
              key={projectBlock.id}
              removeBlock={openPopupDelete}
              editBlock={preparationEditingBlock}
              accountRole={account.role}
              loadProject={reload}
            />
          );
        })}
      </>
    );
  }, [account.role, projectBlocks, reload]);

  const projectBlockDetailed = useMemo(() => {
    return (
      <ContentBlockDetailed
        projectBlocks={projectBlocks}
        removeBlock={openPopupDelete}
        editBlock={preparationEditingBlock}
        accountRole={account.role}
        loadProject={reload}
      />
    );
  }, [account.role, projectBlocks, reload]);
  return (
    <div className={style.wrapper}>
      {contextHolder}
      <div
        className={viewLookBlock === "list" ? style.contentBlockWrapper : null}
      >
        {viewLookBlock === "list" ? projectBlocksList : projectBlockDetailed}
      </div>

      <ContentBlocksForm
        titleDrawer={modeEditOrCreate}
        openDrawer={openDrawer}
        modeForm={modeEditOrCreate}
        onClose={onCloseDrawer}
        onCreateBlock={createBlock}
        onSaveEdit={editBlock}
        dataEdit={dataEdit}
        userList={userList}
        onChangeKey={onChangeKey}
      />

      <ConfirmPopup
        title={`Are you sure
        Delete this block?`}
        popupActive={popupDelete}
        onClose={setPopupDelete}
        onConfirm={onRemoveBlock}
      />
      {!projectBlocks?.length && (
        <EmptyList conditionLoader={true} errorText="No data received" />
      )}
    </div>
  );
};
