import React from 'react';
import { ReactComponent as CloseEye } from './../../../../assets/img/auth/eye-slash.svg';
import { ReactComponent as OpenEye } from './../../../../assets/img/auth/eye.svg';

interface IProps {
  passwordShown: boolean;
  setPasswordShown: (arg: boolean) => void;
}
export const PasswordToggle = ({ passwordShown, setPasswordShown }: IProps) => {
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div style={{ position: 'absolute', top: '10px', right: '5px', cursor: 'pointer' }} onClick={togglePasswordVisibility}>
      {passwordShown ? <CloseEye /> : <OpenEye />}
    </div>
  );
};
