import React, { FC, useEffect, useMemo, useState } from "react";
import style from "./style.module.css";
import { ContentBlock } from "../content-block";
import { IProjectBlock } from "@/typing";

interface IProps {
	projectBlocks?: IProjectBlock[];
	removeBlock?: (e: React.MouseEvent, id: number) => void;
	editBlock?: (e: React.MouseEvent, info: IProjectBlock) => void;
	accountRole: string;
	loadProject: () => void;
}

export const ContentBlockDetailed: FC<IProps> = ({
	projectBlocks,
	removeBlock,
	editBlock,
	accountRole,
	loadProject,
}) => {
  const [detailedBlockId, setDetailedBlockId] = useState<number>();

  const detailedBlockItem = useMemo(() => {
    return projectBlocks?.find((it) => it.id === detailedBlockId);
  }, [detailedBlockId, projectBlocks]);

  const getLastActiveBlockId = () => {
    const item = localStorage.getItem("blockId");
    return JSON.parse(item);
  };

  const getTabBlock = () => {
    const lastActiveId = getLastActiveBlockId();
    if (lastActiveId) {
      setDetailedBlockId(lastActiveId);
    } else {
      setDetailedBlockId(projectBlocks[0]?.id);
    }
  };

  useEffect(() => {
    getTabBlock();
  }, [projectBlocks]);

  const setActiveBlockId = (id: number) =>
    localStorage.setItem("blockId", JSON.stringify(id));

  const handleChangeBlock = (id: number) => {
    setDetailedBlockId(id);
    setActiveBlockId(id);
  };

  return (
    <>
      {detailedBlockItem && (
        <div className={style.wrapper}>
          <div className={style.container}>
            <ul className={style.wrapperTitle}>
              {projectBlocks?.map((it) => {
                return (
                  <li
                    key={it.id}
                    className={` ${style.title} ${
                      detailedBlockId === it.id && style.titleActive
                    } `}
                    onClick={() => handleChangeBlock(it.id)}
                  >
                    {it.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <ContentBlock
            info={detailedBlockItem}
            removeBlock={removeBlock}
            editBlock={editBlock}
            accountRole={accountRole}
            loadProject={loadProject}
          />
        </div>
      )}
    </>
  );
};
