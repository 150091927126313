import React, { FC, useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { ReactComponent as CloseFormIcon } from './../../../assets/img/account/close-form.svg'

interface IProps {
  titleDrawerText?: string
  titleDrawer?: string
  openDrawer: boolean
  onCloseDrawer: () => void
  children: any
  drawerWidth?: string
  additionalStyles?:  React.CSSProperties
}

export const BaseDrawer: FC<IProps> = ({
  openDrawer,
  onCloseDrawer,
  children,
  titleDrawerText,
  drawerWidth,
  additionalStyles,
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(openDrawer)
  }, [openDrawer])

  return (
    <Drawer
      style={{ borderRadius: '15px 0 0 15px', background: '#F9F9F9',  }}
      width={drawerWidth ? drawerWidth : '50%'}
      closeIcon={false}
      onClose={onCloseDrawer}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ borderRadius: '15px 0 0 15px' }}
      headerStyle={{ border: 'none', padding: 0 }}
      title={
        <div
          style={{
            padding: '32px 32px 8px 12px',
            display: 'flex',
            justifyContent: 'space-between',
            ...additionalStyles
          }}
        >
          <p style={{ color: '#131212', fontSize: '24px', fontWeight: '600', lineHeight: '130%' }}>
            {titleDrawerText}
          </p>
          <CloseFormIcon style={{ cursor: 'pointer' }} onClick={onCloseDrawer} />
        </div>
      }
    >
      {children}
    </Drawer>
  )
}
