import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import style from "./styles.module.css";
import { PageWrapper } from "@/core/components/page/page-wrapper";
import { EmptyList } from "@/core/components/empty-list";
import { ProjectTabs } from "../../components/project-tabs";
import { getProjectDetailedReq } from "@/api/projects";
import { IProject } from "@/typing/interfaces/domain/project.interface";
import { message } from "antd";
import { ProjectTabsEnum } from "../../../../typing/enums/project-tabs.enum";
import { PrimaryButton } from "@/core/components";
import { UserRole } from "@/typing/enums/users-role.enum";
import { useSelector } from "react-redux";
import { sectAccount } from "@/store/account/selectors";
import { Context } from "@/modules/root/components/context";
import { IContext } from "@/typing/interfaces/domain/context.interface";
import { appEvents } from "@/core/events";
import { useEventsListener } from "@/core/hooks";
import { TabLeftInfoProject } from "../../components/tab-left-info-project";
import { TabRightContent } from "../../components/tab-right-content";
import { getFavoriteProject } from "@/store/favorite-project/selectors";

export const ProjectDetailedPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id: projectId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [project, setProject] = useState<IProject>(null);
  const navigate = useNavigate();
  const { data: account } = useSelector(sectAccount);
  const [addBlock, setAddBlock] = useState(false);
  const { activeTab, setActiveTab }: IContext = useContext(Context);
  const [titleWikiBtn, setTitleWikiBtn] = useState<string>("Add Wiki");
  const [modTestButton, setModTestButton] = useState<"section" | "test">(
    "section"
  );
  const { state } = useLocation();
  const favoritesProjects = useSelector(getFavoriteProject);
  const isFavoriteProject = favoritesProjects.some(
    (it) => it.projectId === project?.id
  );

  useEventsListener(
    "openWiki",
    (data) => {
      setTitleWikiBtn(data.title);
    },
    []
  );

  useEventsListener(
    "openTest",
    (data) => {
      setModTestButton(data.key);
    },
    []
  );

  const onChangeKey = (key: ProjectTabsEnum) => {
    setActiveTab(key);
  };

  const onClickKeyTab = (key: ProjectTabsEnum) => {
    if (titleWikiBtn === "Edit Page") {
      if (key === ProjectTabsEnum.Wiki) {
        appEvents.emit("openWikiTab", { show: true });
        appEvents.emit("openWiki", { title: "Add Wiki" });
      }
    }
    if (key === ProjectTabsEnum.Testing) {
      appEvents.emit("openTest", { key: "section", isOpen: false });
    }
  };

  useEffect(() => {
    if (state?.activeKey) {
      setActiveTab(state?.activeKey);
    }
  }, [state?.activeKey]);

  const errorError = (error: string) => {
    messageApi.open({
      type: "error",
      content: `Error: ${error}`,
    });
  };

  const loadProject = async () => {
    try {
      setIsLoading(true);
      const { data: project } = await getProjectDetailedReq(+projectId);
      setProject(project);
    } catch (error) {
      errorError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const projectBlocks = project?.blocks.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  useEffect(() => {
    loadProject();
  }, [projectId, state]);


  const createNewWiki = () =>
    appEvents.emit("openDrawer", {
      mode: "create",
      key: "wiki",
      isOpen: true,
    });

  const createNewSection = () => {
    appEvents.emit("openTest", {
      mode: "create",
      key: "section",
      isOpen: true,
    });
  };

  const createNewTest = () => {
    appEvents.emit("openTest", {
      mode: "create",
      key: "test",
      isOpen: true,
    });
  };

  const ProjectDetailedButton = () => {
    const renderButton = useMemo(() => {
      if (activeTab === ProjectTabsEnum.Wiki) {
        return (
          <PrimaryButton
            text={titleWikiBtn}
            type="button"
            individualStyle={{ width: 213 }}
            onClick={createNewWiki}
          />
        );
      }
      if (activeTab === ProjectTabsEnum.Testing) {
        return (
          <>
            {modTestButton === "section" ? (
              <PrimaryButton
                text={"Create new section"}
                type="button"
                individualStyle={{ width: 213, height: 40 }}
                onClick={createNewSection}
              />
            ) : (
              <PrimaryButton
                text={"Create new test"}
                type="button"
                individualStyle={{ width: 213, height: 40 }}
                onClick={createNewTest}
              />
            )}
          </>
        );
      }

      return null;
    }, [activeTab, projectId, navigate, account.role]);

    return <div className={style.buttonTab}>{renderButton}</div>;
  };

  return (
    <PageWrapper>
      <div className={style.wrapper}>
        {contextHolder}
        {project ? (
          <div className={style.inner}>
            <div>
              <ProjectTabs
                projectBlocks={projectBlocks}
                reload={loadProject}
                onChangeKey={onChangeKey}
                setAddBlock={setAddBlock}
                addBlock={addBlock}
                projectInfo={project}
                onClickKeyTab={onClickKeyTab}
                tabBarExtraContent={{
                  left: (
                    <TabLeftInfoProject
                      project={project}
                      isFavorite={isFavoriteProject}
                    />
                  ),
                  right:
                    activeTab === "block" ? (
                      <TabRightContent
                        button={
                          <PrimaryButton
                            individualStyle={{
                              width: "213px",
                              borderRadius: "24px",
                              fontWeight: 300,
                            }}
                            type="button"
                            text="Add new block"
                            onClick={() => setAddBlock(true)}
                          />
                        }
                      />
                    ) : (
                      <ProjectDetailedButton />
                    ),
                }}
              />
            </div>
          </div>
        ) : (
          <EmptyList
            conditionLoader={!isLoading}
            errorText="No data received"
          />
        )}
      </div>
    </PageWrapper>
  );
};
