import React from "react";
import { publicWikiRoute } from "@/modules/wiki/config";
import { PublicWikiPage } from "@/modules/wiki/pages/public-wiki-page";
import { Navigate } from "react-router-dom";

export const routersPublic = [
  {
    path: publicWikiRoute,
    element: <PublicWikiPage />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];
