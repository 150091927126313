import React, { FC, ReactNode } from "react";
import style from "./style.module.css";
import { PrimaryButton } from "@/core/components/buttons/primary-button";
import { SecondaryButton } from "@/core/components/buttons/secondary-button";

interface IPopupActive {
	isShow: boolean;
	id: number | null;
}

interface IProps {
	popupActive: IPopupActive;
	onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
	title: string;
	onClose: (popupActive: IPopupActive) => void;
	children?: ReactNode;
}

export const ConfirmPopup: FC<IProps> = ({
	popupActive,
	onConfirm,
	title,
	onClose,
	children,
}: IProps) => {
	if (!popupActive.isShow) return null;

	const onCancel = () => {
		onClose({ isShow: false, id: null });
	};

	return (
		<div className={style.popupOverlay}>
			<div className={style.popupContainer}>
				<p className={style.popupText}>{title}</p>
				{children}
				<div className={style.popupButtons}>
					<SecondaryButton
						individualStyle={{ width: 213 }}
						type="button"
						text="No"
						onClick={onCancel}
					/>
					<PrimaryButton
						individualStyle={{ width: 213 }}
						type="button"
						text="Yes"
						onClick={onConfirm}
					/>
				</div>
			</div>
		</div>
	);
};
