import { ILoginPayload, IRegistrationPayload, IPasswordRecoveryStep1, IPasswordRecoveryStep2, IChangePasswordPayload } from './interfaces';
import { http } from '../http.service';
import { ITokenPair } from '@/typing/interfaces/system/token-pair.interface';

export const loginInReq = (payload: ILoginPayload) => {  
  return http.post<ITokenPair>('/auth/login', { ...payload, deviceName: 'd' });
};

export const registrationInReq = (payload: IRegistrationPayload) => {

  return http.post<ITokenPair>('/auth/register', { ...payload, deviceName: 'd' });
};

export const passwordRecoveryStep1Req = (payload: IPasswordRecoveryStep1) => {
  return http.post('/auth/password-recovery/step-1', payload ); 
};
export const passwordRecoveryStep2Req = (payload: IPasswordRecoveryStep2) => {
  return http.post('/auth/password-recovery/step-2', payload );
};
export const confirmPasswordReq = (payload: IChangePasswordPayload) => {
  return http.post<ITokenPair>('/auth/password-recovery/step-3',  { ...payload, deviceName: 'd' } );
};