import { TestingPriority } from "@/typing/enums/testing-priority.enum";

export const colorTagPriority = (priority: string) => {
  if (priority === TestingPriority.Low) return "#DBFFE5";
  if (priority === TestingPriority.Medium) return "#FFFFC2";
  if (priority === TestingPriority.High) return "#FFD6BF";
  if (priority === TestingPriority.Critical) return "#fc00007d";
};
export const colorTextTagPriority = (priority: string) => {
  if (priority === TestingPriority.Low) return "#0F833D";
  if (priority === TestingPriority.Medium) return "#FF7A00";
  if (priority === TestingPriority.High) return "#D43A18";
  if (priority === TestingPriority.Critical) return "#972929";
};
