import React, { useMemo, useState } from "react";
import { ReactComponent as PlaneIcon } from "@/assets/img/project/plane.svg";
import style from "./style.module.css";
import { ReactComponent as ArrowIcon } from "./../../../../assets/img/account/arrow.svg";
import { EditorMarkdown, UploadFiles } from "@/core/components";
import "react-markdown-editor-lite/lib/index.css";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import _ from "lodash";
import { shortFileName } from "../../helpers";
import { Tag } from "antd";
interface IProps {
  hideToolbar: boolean;
  setValueTextarea: (value: string) => void;
  toolbarOnClick: () => void;
  valueTextarea: string;
  contentCreate: () => void;
  editorHeight: number;
  viewLookBlock: "list" | "detailed";
  selectFiles: (files: File[]) => void;
  files: File[];
}

export const ContentBlockFooter = ({
  hideToolbar,
  setValueTextarea,
  toolbarOnClick,
  valueTextarea,
  contentCreate,
  viewLookBlock,
  selectFiles,
  files,
}: IProps) => {
  const [isShowToolBar, setIsShowToolBar] = useState<boolean>(true);
  const [previewEdit, setPreviewEdit] = useState<string>("");

  const removeFile = (name: string) => {
    const templ = files.filter((it) => it.name !== name);
    selectFiles(templ);
  };
  const filesList = useMemo(() => {
    if (isShowToolBar) return null;
    return (
      <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
        {files.map((it) => {
          return (
            <Tag closable key={it.name} onClose={() => removeFile(it.name)}>
              {shortFileName(it.name)}
            </Tag>
          );
        })}
      </div>
    );
  }, [files, isShowToolBar, files]);

  const textEeditor = useMemo(() => {
    if (!isShowToolBar) {
      return (
        <EditorMarkdown
          hideToolbar={true}
          onChange={(e) => {
            setPreviewEdit(e);
            setValueTextarea(e);
          }}
          value={previewEdit}
        />
      );
    }
    return (
      <textarea
        placeholder="Write message..."
        className={style.textarea}
        value={previewEdit}
        onChange={(e) => {
          setPreviewEdit((prev) => (prev = e.target.value));
          setValueTextarea(e.target.value);
        }}
      />
    );
  }, [isShowToolBar, previewEdit, setValueTextarea]);

  return (
    <div
      className={`${style.cardFooter} 
				${
          hideToolbar
            ? style.cardFooterClose
            : `${
                viewLookBlock === "list"
                  ? style.cardFooterOpen
                  : style.cardFooterOpenDetailed
              }`
        }
				
			`}
    >
      <div className={style.markDownContainer}>
        <div className={style.markDownEditor}>
          {filesList}
          {textEeditor}
        </div>

        <div
          className={`${style.markDownBtn}  ${
            !hideToolbar ? style.markHide : null
          }`}
        >
          <button
            className={style.setHiddenButton}
            onClick={() => {
              toolbarOnClick();
              setIsShowToolBar((prev) => !prev);
            }}
          >
            <ArrowIcon
              className={hideToolbar === false ? style.openBar : style.closeBar}
            />
          </button>
          <button
            className={style.planeBtn}
            disabled={!valueTextarea && _.isEmpty(files)}
            onClick={() => {
              contentCreate();
              setIsShowToolBar((prev) => (prev = true));
              setPreviewEdit("");
            }}
          >
            <PlaneIcon className={style.planeIcon} />
          </button>

          {!hideToolbar ? <UploadFiles onChange={selectFiles} /> : null}
        </div>
      </div>
    </div>
  );
};
