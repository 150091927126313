import { UserRole } from "@/typing";
import { UserPosition } from "@/typing/enums/user-position.enum";

export  const optionsUserPosition = [
    { label: 'Manager', value: UserPosition.Manager },
    { label: 'Developer', value: UserPosition.Developer },
    { label: 'Tester', value: UserPosition.QA },
    { label: 'Designer', value: UserPosition.Designer },
    { label: 'Admin', value: UserPosition.Admin },
  ]

export const optionsUserRole = [
    { label: 'Admin', value: UserRole.Admin },
    { label: 'User', value: UserRole.User },
    { label: 'Manager', value: UserRole.Manager },
  ]
