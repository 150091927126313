import React from "react";
import styles from "./style.module.css";
import { ReactComponent as EyeIcon } from "@/assets/img/project/eye.svg";
import { ReactComponent as EditIcon } from "@/assets/img/users/edit.svg";
import { blockService } from "@/services/domain";
import { useSelector } from "react-redux";
import { getModeViewBlock } from "@/store/shared/selectors";

export const SwitchBlockTab = () => {
	const isModeViewBlock = useSelector(getModeViewBlock);

	const onToggleRevisionBlock = async (isModeViewBlock: boolean) => {
		await blockService.toggleModeViewBlock(isModeViewBlock);
	};

	return (
		<label className={styles.toggleSwitch}>
			<span className={styles.iconEye}>
				<EyeIcon
					className={
						isModeViewBlock ? styles.iconStroke : styles.iconStrokeActive
					}
				/>
			</span>
			<input
				type="checkbox"
				checked={isModeViewBlock}
				onChange={() => onToggleRevisionBlock(!isModeViewBlock)}
			/>
			<span className={styles.switch} />
			<span className={styles.iconEdit}>
				<EditIcon
					className={isModeViewBlock ? styles.iconFillActive : styles.iconFill}
					width={20}
					height={20}
				/>
			</span>
		</label>
	);
};
