import React, { FC } from "react";
import style from "./style.module.css";
import MarkdownPreview from "@uiw/react-markdown-preview";

interface IProps {
	content: string;
}

export const RecordContentsItem: FC<IProps> = ({ content }) => {
	return (
		<li className={style.item}>
			<MarkdownPreview linkTarget="_blank" disableCopy source={content} />
		</li>
	);
};
