import React, { useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { ConfigProvider, InputRef } from 'antd'
import { Space, Input, Tag, Tooltip, theme } from 'antd'

interface IProps {
  tags: string[]
  setTags: (data: string[]) => void
  tagLabel?: string
}

export const AccountTags = ({tagLabel='Technologies', tags, setTags }: IProps) => {
  const { token } = theme.useToken()
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [inputValue])

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tags]
    newTags[editInputIndex] = editInputValue
    setTags(newTags)
    setEditInputIndex(-1)
    setInputValue('')
  }

  const tagInputStyle: React.CSSProperties = {
    marginTop: '10px',
    width: 78,
    verticalAlign: 'top',
  }

  const tagPlusStyle: React.CSSProperties = {
		marginTop: "10px",
		background: token.colorBgContainer,
		borderStyle: "dashed",
		marginLeft: inputVisible ? "10px" : 0,
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#d9d9d9",
				},
			}}>
			<Space size={[0, 8]} wrap>
				<div
					style={{
						color: "#444647",
						fontSize: "18px",
						fontWeight: "300",
						lineHeight: "130%",
						paddingRight: "8px",
						marginTop: "10px",
					}}>
					{tagLabel}
				</div>
				{tags?.map((tag, index) => {
					if (editInputIndex === index) {
						return (
							<Input
								ref={editInputRef}
								key={tag}
								size="small"
								style={tagInputStyle}
								value={editInputValue}
								onChange={handleEditInputChange}
								onBlur={handleEditInputConfirm}
								onPressEnter={handleEditInputConfirm}
							/>
						);
					}
					const tagElem = (
						<Tag
							key={tag}
							closable={true}
							style={{
								userSelect: "none",
								lineHeight: "22.4px",
								color: "#131212",
								fontSize: "16px",
								border: "none",
								borderRadius: "2px",
								background: "#EAEDEF",
								display: "flex",
								marginTop: "10px",
							}}
							onClose={() => handleClose(tag)}>
							<span
								onDoubleClick={(e) => {
									if (index !== 0) {
										setEditInputIndex(index);
										setEditInputValue(tag);
										e.preventDefault();
									}
								}}>
								{tag}
							</span>
						</Tag>
					);
					return (
						<Tooltip title={tag} key={tag}>
							{tagElem}
						</Tooltip>
					);
				})}
				{inputVisible && (
					<>
						<Input
							ref={inputRef}
							type="text"
							size="small"
							style={tagInputStyle}
							value={inputValue}
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
						/>
					</>
				)}
				<Tag style={tagPlusStyle} onClick={showInput}>
					<PlusOutlined />
				</Tag>
			</Space>
		</ConfigProvider>
	);
}
