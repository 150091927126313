import React, { useState } from 'react'
import style from './styles.module.css'
import user from '../../../../assets/img/auth/user.svg'
import { message } from 'antd'
import { ChangePasswordEnterEmail } from '../../components/change-password-enter-email'
import { ChangePasswordEnterCode } from '../../components/change-password-enter-code'
import { ChangePasswordEnterPassword } from '../../components/change-password-enter-password'
import { passwordRecoveryStep1Req, passwordRecoveryStep2Req } from '@/api'
import { IChangePasswordPayload } from '@/api/auth/interfaces'
import { authService } from '@/services/domain/auth.services'

export const PasswordChangePage = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [email, setEmail] = useState<string>()
  const [code, setCode] = useState<string>()
  const [step, setStep] = useState<'step1' | 'step2' | 'step3'>('step1')

  const errorError = (error: string) => {
    messageApi.open({
      type: 'error',
      content: `Error: ${error}`,
    })
  }

  const successfulPasswordChange = () => {
    messageApi.info('Successful password change!')
  }

  const onSubmitStep1 = async () => {
    try {
      await passwordRecoveryStep1Req({ email })
      setStep('step2')
    } catch (error) {
      const message = await error.data?.message.toString()
      errorError(message || error?.message)
    }
  }

  const onSubmitStep2 = async () => {
    try {
      await passwordRecoveryStep2Req({ email, code })
      setStep('step3')
    } catch (error) {
      const message = await error.data?.message.toString()
      errorError(message || error?.message)
    }
  }

  const onSubmitStep3 = async ({ ...data }: IChangePasswordPayload) => {
    try {
      await authService.confirmChangePassword(data.email, data.password)
      successfulPasswordChange()
    } catch (error) {
      const message = await error.data?.message.toString()
      errorError(message || error?.message)
    }
  }

  return (
    <div className={style.container}>
      {contextHolder}
      <div className={style.content}>
        <div className={style.iconContainer}>
          <img src={user} alt="" />
        </div>
        <h2 className={style.header}>Forgot Password</h2>

        {step === 'step1' ? (
          <ChangePasswordEnterEmail
            email={email}
            onChangeEmail={setEmail}
            onSubmit={onSubmitStep1}
          />
        ) : null}
        {step === 'step2' ? (
          <ChangePasswordEnterCode
            timerRefresh={onSubmitStep1}
            onChangeCode={setCode}
            onSubmit={onSubmitStep2}
          />
        ) : null}
        {step === 'step3' ? (
          <ChangePasswordEnterPassword email={email} onSubmit={onSubmitStep3} />
        ) : null}
      </div>
    </div>
  )
}
