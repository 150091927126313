import * as Yup from 'yup'

export const validationSchemaForm = Yup.object().shape({
	name: Yup.string().required("Name is required").trim(),
	technologies: Yup.string()
		.required("Technologies / Languages is required")
		.trim(),
	description: Yup.string().required("Description is required").trim(),
	testing: Yup.array().required("Testing is required"),
	developers: Yup.array().required("Testing is required"),
	manager: Yup.string().required("Testing is required"),
});
