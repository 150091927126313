import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { schemaFormWiki } from "../validations";
import { patchWikiRequest, postWikiRequest } from "@/api/wiki";
import { IWiki } from "@/typing";
import { message } from "antd";

interface IFrom {
	title: string;
	content: string;
	optionalNote: string;
}

export const useFormWiki = (
	projectId: number,
	openCloseDrawer: () => void,
	wiki: IWiki
) => {
	const {
		register,
		handleSubmit,
		reset,
		setFocus,
		setValue,
		control,
		formState: { errors, isValid },
	} = useForm<IFrom>({
		mode: "onChange",
		resolver: yupResolver(schemaFormWiki),
		values: { ...wiki },
		defaultValues: {
			title: "",
			content: "",
		},
	});

	const onSubmit = async (data: IWiki) => {
		try {
			wiki
				? await patchWikiRequest(data)
				: await postWikiRequest({ ...data, projectId: projectId });
			reset();
			openCloseDrawer();
			message.success({
				type: "success",
				content: `Age creation wiki completed successfully`,
			});
		} catch (error) {
			console.log(error);
			message.open({
				type: "error",
				content: `Error: ${error}`,
			});
		} finally {
			reset({
				title: "",
				content: "",
			});
		}
	};

	return {
		onSubmit: handleSubmit(onSubmit),
		register,
		errors,
		control,
		isValid,
		setFocus,
		reset,
		setValue,
	};
};
