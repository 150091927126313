import { firstLetter, getTextPreview } from "@/core/helpers";
import { Tooltip } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import style from "./style.module.css";

interface IProps {
  projectId: number;
  isShowSideBar: boolean;
  imgUrl: string;

  projectName: string;
  classNameLabel?: string;
}

export const ItemFavoriteProject: FC<IProps> = ({
  projectId,
  isShowSideBar,
  projectName,
  imgUrl,
  classNameLabel,
}) => {
  return (
    <li>
      <Link to={`/project/${projectId}`}>
        <button
          className={` ${
            !isShowSideBar
              ? `${style.wrapperContent} ${style.showContent}`
              : style.wrapperContent
          }`}
        >
          <div className={style.imageWraper}>
            <Tooltip placement="right" title={"Project"}>
              {imgUrl === "null" ? (
                <span className={style.projectTopLetter}>
                  {firstLetter(projectName)}
                </span>
              ) : (
                <img className={style.image} src={imgUrl} alt="avatar-user" />
              )}
            </Tooltip>
          </div>
          <span className={`${classNameLabel} ${style.text}`}>
            {getTextPreview(projectName, 18)}
          </span>
        </button>
      </Link>
    </li>
  );
};
