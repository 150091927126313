import React, { useState } from 'react'
import style from './styles.module.css'
import user from '../../../../assets/img/auth/user.svg'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaLogin } from '@/modules/auth/valid-schema/validation-schema-login'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '@/core/components/buttons/primary-button'
import { SecondaryButton } from '@/core/components/buttons/secondary-button'
import { InputBox } from '@/core/components/form/input-box'
import { message } from 'antd'
import { authService } from '@/services/domain/auth.services'
import { UserLoginForm } from '../../interface/login-form.interface'
import { PasswordToggle } from '@/core/components/form/password-toggle'
import { passwordChange, registrationRoute } from '../../config'

export const LogInPage = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [passwordShown, setPasswordShown] = useState<boolean>(false)

  const errorError = (error: string) => {
    messageApi.open({
      type: 'error',
      content: `Error: ${error}`,
    })
  }
  const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<UserLoginForm>({
		resolver: yupResolver(validationSchemaLogin),
	});

  const onSubmit = async (data: UserLoginForm) => {
    try {
      await authService.signIn(data.email, data.password)
    } catch (error) {
      const message = await error.data?.message.toString()
      errorError(message || error?.message)
    }
  }

  return (
		<div className={style.container}>
			{contextHolder}
			<div className={style.content}>
				<div className={style.iconContainer}>
					<img src={user} alt="" />
				</div>
				<h2 className={style.header}>Login</h2>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.login}>
						<InputBox
							register={register}
							label="User Email"
							placeholder="Enter Email"
							error={errors.email?.message}
							keyField={"email"}
						/>
						<InputBox
							register={register}
							label="Password"
							placeholder="Enter Password"
							error={errors.password?.message}
							keyField={"password"}
							type={passwordShown ? "text" : "password"}>
							<PasswordToggle
								passwordShown={passwordShown}
								setPasswordShown={setPasswordShown}
							/>
						</InputBox>
						<Link className={style.forgotPassword} to={passwordChange}>
							Forgot password
						</Link>
					</div>
					<div className={style.buttonGroup}>
						<PrimaryButton type="submit" text="Login in"></PrimaryButton>
						<SecondaryButton
							onClick={() => reset()}
							type="button"
							text="Cancel"></SecondaryButton>
					</div>
					<Link className={style.link} to={registrationRoute}>
						To register →
					</Link>
				</form>
			</div>
		</div>
	);
}
