import { PrimaryButton, SecondaryButton } from '@/core/components'
import { BaseDrawer } from "@/core/components/drawers/base-drawer";
import { Textarea } from "@/core/components/form/textarea-box";
import { ProjectTabsEnum } from "@/typing/enums/project-tabs.enum";
import { ITesting } from "@/typing/interfaces/domain/testing.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	optionsPriority,
	optionsType,
	optionsAutomationType,
} from "../../config/options.config";
import { ITestingForm } from "../../interface/testing-form.interface";
import { validationSchemaTesting } from "../../valid-schema/validation-schema-testing";
import style from "./style.module.css";
import { InputFormBox } from "@/core/components/form/input-form-box";
import { SelectControl } from "@/core/components/form/select-contol";

interface IProps {
  openDrawer: boolean;
  modeForm: string;
  onClose: () => void;
  dataEdit: ITesting;
  onSaveTest?: (data: ITesting) => void;
  onSaveEdit?: (data: ITesting) => void;
  onChangeKey?: (key: ProjectTabsEnum) => void;
  sectionTitle?: string;
  sectionId: number;
}

export const TestingForm = ({
  onSaveTest,
  onSaveEdit,
  openDrawer,
  modeForm,
  onClose,
  dataEdit,
  sectionTitle,
  sectionId,
}: IProps) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<ITestingForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchemaTesting),
    values: { ...dataEdit },
    defaultValues: {
      name: null,
      preconditions: null,
      steps: null,
      expectedResult: null,
      priorities: null,
      testCaseType: null,
    },
  });

  const renderOptions = (option: any) => {
    return option.map((it: any) => {
      return (
        <Select.Option key={it.value} label={it.label}>
          {it.label}
        </Select.Option>
      );
    });
  };

  const onSubmit = (data: ITesting) => {
    if (modeForm === "create") {
      onSaveTest({ ...data, sectionId: sectionId });
    } else if (modeForm === "edit") {
      onSaveEdit(data);
    }
    reset();
    onClose();
  };

  useEffect(() => {
    reset();
  }, [openDrawer]);

  return (
    <BaseDrawer
      titleDrawerText={modeForm === "create" ? "Create new test" : "Edit test"}
      openDrawer={openDrawer}
      onCloseDrawer={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <div className={style.sectionTitle}>{sectionTitle}</div>
          <InputFormBox
            label="Title"
            placeholder="Write title of page"
            keyField={"name"}
            register={register}
          />
          <div className={style.selectGroup}>
            <div style={{ width: "100%" }}>
              <h5 className={style.label}>Priority</h5>
              <SelectControl
                name="priorities"
                renderOptions={() => renderOptions(optionsPriority)}
                dataEdit={dataEdit?.priorities}
                control={control}
                register={register}
              />
            </div>
            <div style={{ width: "100%" }}>
              <h5 className={style.label}>Type </h5>
              <SelectControl
                name="testCaseType"
                renderOptions={() => renderOptions(optionsType)}
                dataEdit={dataEdit?.testCaseType}
                control={control}
                register={register}
              />
            </div>
            <div style={{ width: "100%" }}>
              <h5 className={style.label}>Automation Type </h5>
              <SelectControl
                name="automationType"
                renderOptions={() => renderOptions(optionsAutomationType)}
                dataEdit={dataEdit?.automationType}
                control={control}
                register={register}
              />
            </div>
          </div>

          <Textarea
            styleLabel={{
              color: "#444647",
              fontSize: 18,
              fontWeight: 300,
              lineHeight: "130%",
            }}
            register={register}
            label="Preconditions"
            keyField={"preconditions"}
            error={errors.preconditions?.message}
          />
          <Textarea
            styleLabel={{
              color: "#444647",
              fontSize: 18,
              fontWeight: 300,
              lineHeight: "130%",
            }}
            register={register}
            label="Steps"
            keyField={"steps"}
            error={errors.steps?.message}
          />
          <Textarea
            styleLabel={{
              color: "#444647",
              fontSize: 18,
              fontWeight: 300,
              lineHeight: "130%",
            }}
            register={register}
            label="Expected result"
            keyField={"expectedResult"}
            error={errors.expectedResult?.message}
          />

          <div className={style.buttonGroup}>
            <SecondaryButton
              individualStyle={{ width: 213 }}
              onClick={onClose}
              text="Cancel"
            />
            <PrimaryButton
              individualStyle={{ width: 213 }}
              type="submit"
              text={modeForm === "create" ? "Create" : "Save"}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </BaseDrawer>
  );
};
