import { Events } from "jet-tools";

export type AppEvents = {
  openDrawer: {};
  openTest: {
    mode?: "create" | "edit";
    isOpen?: boolean;
    key?: "section" | "test";
    reload?: boolean;
  };
  openWiki: {
    title?: string;
  };
  openWikiTab: {
    show: boolean;
  };
  openBlockPopup: {
    isOpen: boolean;
  };
};

export const appEvents = new Events<AppEvents>();
