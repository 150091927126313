import React, { useState, useEffect } from 'react'

interface IProps {
  timerRefresh: () => void;
}

export const ChangePasswordTimer = ({ timerRefresh }: IProps) => {
  const [timeLeft, setTimeLeft] = useState(120)
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer)
          setShowRefreshButton(true)
          return prevTime
        }
        return prevTime - 1
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [timeLeft])

  const handleRefresh = () => {
    timerRefresh()
    setTimeLeft(120)
    setShowRefreshButton(false)
  }

  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft % 60

  return (
    <div>
      {showRefreshButton ? null : (
        <div style={{ color: '#686868' }}>
          The code will be active for: {minutes}:{seconds < 10 ? '0' : ''}
          {seconds}
        </div>
      )}

      {showRefreshButton && (
        <button style={{ color: '#636363', cursor: 'pointer' }} onClick={handleRefresh}>
          Refresh Code
        </button>
      )}
    </div>
  )
}
