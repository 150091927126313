import { IWiki } from '@/typing/interfaces/domain/wiki.interface'
import { IPaginationReq } from '@/typing/interfaces/system/paginations.interface'
import { http } from '../http.service'
import { IGetWikiDetailedResponse, IGetWikiResponse, IPatchWikiResponse, IPostWikiResponse } from './interface'


export const getWikiListRequest  = (params: IPaginationReq & { projectId: string }) => {
    return http.get<IGetWikiResponse>('/wiki/list', { params },)
  }

  export const getWikiDetailRequest = (payload: number) => {
    return http.get<IGetWikiDetailedResponse>(`/wiki/${payload}`)
  }
  
  export const patchWikiRequest = (payload: IWiki) => {
    return http.patch<IPatchWikiResponse>(`/wiki/${payload.id}`, payload)
  }
  
  export const deleteWikiRequest = (payload: number) => {
    return http.delete(`/wiki/${payload}`)
  }
  export const postWikiRequest = (payload: any) => {
    return http.post<IPostWikiResponse>(`/wiki`, payload)
  }
 