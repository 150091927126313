import React, { FC } from "react";
import style from "./style.module.css";
import { IUser } from "@/typing";
import { Tooltip } from "antd";
import { ReactComponent as UserIcon } from "../../../../../../assets/img/left-side-bar/user.svg";
import { userPositionConfig } from "@/modules/users/config/user-position.config";

interface IProps {
  account: IUser;
  splitLocation: string;
  showSideBar: boolean;
}
export const AccountSideBar: FC<IProps> = ({
  account,
  splitLocation,
  showSideBar,
}) => {
  const activeAccount = splitLocation === "account" ? style.active : "";
  return (
    <div className={style.container}>
      <div className={style.accountContainer}>
        <div className={style.accountRow}>
          <Tooltip
            placement="right"
            title={"Account"}
            className={style.imageWraper}
          >
            {account.avatarUrl ? (
              <img
                className={showSideBar ? style.image : style.image_clossable}
                src={account.avatarUrl}
                alt="avatar-user"
              />
            ) : (
              <span className={`${activeAccount}`}>
                <i className={`${style.icon} fa-regular fa-circle-user`}></i>
              </span>
            )}
          </Tooltip>
          <div>
            <p
              className={`${
                showSideBar ? style.label_tab : style.label_tab_clossable
              } ${activeAccount}`}
            >
              {account.name} {account.lastName}
            </p>
            <p
              className={`${
                showSideBar ? style.label_tab : style.label_tab_clossable
              }  ${activeAccount}
                   `}
            >
              {!account?.positions
                ? ""
                : userPositionConfig[account?.positions[0]]}
            </p>
          </div>
        </div>
      </div>
      {/* <div className={style.deriver} /> */}
    </div>
  );
};
