import React from "react";
import { ProjectTabsEnum } from "../../../typing/enums/project-tabs.enum";
import { IProjectBlock } from "@/typing/interfaces/domain/project-block.interface";
import { ProjectInfo } from "../components/project-info";
import { IProject } from "@/typing/interfaces/domain/project.interface";
import { WikiPage } from "@/modules/wiki/pages/wiki-page";
import { ContentBlockViewSmart } from "../smart-components";
import { SectionsListPage } from "@/modules/testing/pages";

interface IProps {
  addBlock: boolean;
  setAddBlock: (data: boolean) => void;
  reload: () => void;
  projectBlocks: IProjectBlock[];
  projectInfo: IProject;
  onChangeKey: (key: ProjectTabsEnum) => void;
  onClickKeyTab: (key: any) => void;
}

export const projectTabsConfig = ({
  addBlock,
  setAddBlock,
  reload,
  projectBlocks,
  projectInfo,
  onChangeKey,
}: IProps) => {
  return [
    {
      key: ProjectTabsEnum.Block,
      label: `Block`,
      children: (
        <ContentBlockViewSmart
          addBlock={addBlock}
          setAddBlock={setAddBlock}
          onChangeKey={onChangeKey}
          reload={reload}
          projectBlocks={projectBlocks}
        />
      ),
    },
    {
      key: ProjectTabsEnum.Wiki,
      label: `Wiki`,
      children: <WikiPage />,
    },
    {
      key: ProjectTabsEnum.Testing,
      label: `Testing`,
      children: <SectionsListPage />,
    },
    {
      key: ProjectTabsEnum.Info,
      label: `Info`,
      children: <ProjectInfo projectInfo={projectInfo} />,
    },
    {
      key: ProjectTabsEnum.Docs,
      label: `Docs`,
      children: <ProjectInfo projectInfo={projectInfo} />,
    },
  ];
};
