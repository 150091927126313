import React, { useState } from 'react'
import _ from 'lodash'

import style from './styles.module.css'
import { message, Tag } from "antd";
import { useSelector } from "react-redux";
import { AccountForm } from "../../components";
import { sectAccount } from "@/store/account/selectors";
import { accountService } from "@/services/domain/account.services";
import { categoriesConfig } from "../../config";
import { IUser, UserProject } from '@/typing'
import { Loader, PageWrapper } from "@/core/components";
import { AccountCard } from '../../../../core/components/account-card'

export const AccountPage: React.FC = () => {
  const { data: account, isLoading } = useSelector(sectAccount)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [dataEdit, setDataEdit] = useState<IUser>(null)
  const [messageApi, contextHolder] = message.useMessage()

  const [tags, setTags] = useState(account?.technologies)

  const errorError = (error: string) => {
    messageApi.open({
      type: 'error',
      content: `Error: ${error}`,
    },
    )
  }
  const success = (success : string) => {
    messageApi.open({
      type: 'success',
      content: `${success}`,
    });
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false)
    setDataEdit(null)
  }

  const editAccount = async (newData: IUser) => {
    try {
      await accountService.patchAccount(newData)
    } catch (error) {
      errorError('No changes sent')
    }
  }

  const preparationEditingAccount = () => {
    setOpenDrawer(true)
    setDataEdit(account)
  }

  const renderProjects = () => {
    const projectMap = new Map()

    account?.userProjects?.forEach((userProject: UserProject) => {
      const { project, category } = userProject
      const projectName = project.name

      if (projectMap.has(projectName)) {
        projectMap.get(projectName)?.push(categoriesConfig[category])
      } else {
        projectMap.set(projectName, [categoriesConfig[category]])
      }
    })

    return (
      <ul>
        {Array.from(projectMap.entries()).map(([projectName, categories]) => (
          <li className={style.projectItem} key={projectName}>
            <div>
              <span className={style.itemText}>{'Name:  '}</span>
              <Tag className={style.tag}>{projectName}</Tag>
            </div>
            <div>
              <span className={style.itemText}>{'Position:  '}</span>
              <span className={style.itemInfo}>{categories.join(', ')}</span>
            </div>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <PageWrapper blockSearch={false}>
      {contextHolder}
      {!_.isEmpty(account) && !isLoading ? (
        <div className={style.wrapper}>
          <h2 className={style.pageTitle}>Account</h2>
          <AccountCard
            success={success}
            mode="account"
            preparationEditingAccount={preparationEditingAccount}
            account={account}
            renderProjects={renderProjects}
          />

          <AccountForm
            titleDrawerText={'Edit account'}
            mode="account"
            openDrawer={openDrawer}
            onClose={onCloseDrawer}
            onSaveEdit={editAccount}
            dataEdit={dataEdit}
            tags={tags}
            setTags={setTags}
          />
        </div>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  )
}
