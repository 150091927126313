import React, { FC } from "react";
import { geTypeFileContent, shortFileName } from "../../helpers";
import { FileOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import { IContentFile } from "@/typing";
interface IProps {
  files: IContentFile[];
}
export const FilesContent: FC<IProps> = ({ files }) => {
  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        {files.map((it, index) => {
          if (it.type === "image") {
            return (
              <li>
                <a onClick={() => window.open(it.url, "_blank")}>
                  <img
                    src={it.url}
                    style={{ height: 30, width: 30, borderRadius: 10 }}
                    alt=""
                  />
                </a>
              </li>
            );
          }
          return (
            <li>
              <a download onClick={() => window.open(it.url, "_blank")}>
                <div className={styles.fileItem}>
                  <FileOutlined style={{ fontSize: 20 }} />
                  <p style={{ color: "#000000", fontSize: 12 }}>{it.name}</p>
                </div>
              </a>
            </li>
          );
        })}
      </div>
    </div>
  );
};
