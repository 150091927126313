import { PrimaryButton } from "@/core/components";
import React, { FC, useState } from "react";
import { publicWikiRoute } from "../../config";
import { generatePath } from "react-router-dom";
import { ReactComponent as CopyIcon } from "@/assets/img/project/copy.svg";
import styles from "./style.module.css";

interface IProps {
	wikiId: number;
}
export const ShareWikiButton: FC<IProps> = ({ wikiId }) => {
	const [shareLink, setShareLink] = useState("");
	const originalUrl = window.origin;
	const pathWiki = generatePath(publicWikiRoute, { id: wikiId });
	const generateShareLink = () => {
		const baseShareLink = `${originalUrl}${pathWiki}`;

		setShareLink(baseShareLink);
	};

	const copyContent = (text: string) => {
		navigator.clipboard.writeText(text);
	};

	return (
		<div style={{ marginBottom: 24 }}>
			<PrimaryButton
				individualStyle={{
					width: 213,
					marginBottom: 16,
				}}
				text="Share wiki"
				onClick={() => generateShareLink()}
			/>
			{shareLink && (
				<div className={styles.linkContainer}>
					<a
						style={{ color: "#1068FF" }}
						href={shareLink}
						target="_blank"
						rel="noopener noreferrer">
						{shareLink}
					</a>
					<button
						className={styles.btnWrapper}
						onClick={() => copyContent(shareLink)}>
						<CopyIcon className={styles.icon} title="Copy" />
					</button>
				</div>
			)}
		</div>
	);
};
