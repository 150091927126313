import { Service } from "@/core/abstract";
import { message } from "antd";
import { presignedUploaderService } from "../system/file.service";
import { IFile } from "@/typing";
import { finishUploadProjectReq, getUploadProjectUrlReq, patchProjectReq, storeProjectReq } from "@/api/projects";
import { IProject } from "@/typing/interfaces/domain/project.interface";

class ProjectService extends Service {
    
    public async createProject(projectData: any) {
      try {
      
        
        const { data }: any = await storeProjectReq(projectData);

        
        
        if (projectData?.imageUrl) {
          this.loadFile(projectData.imageUrl, data.id );
        }
  
        message.success("Project created success");
      } catch (error) {
        message.error(error.data.message);
      }
    }
  
    public async updateProject(projectData: IProject ) {
      try {
        await patchProjectReq(projectData);
  
        if (projectData?.imageUrl) {
          this.loadFile(projectData.imageUrl, projectData.id );
        }
  
        message.success("Project update success");
      } catch (error) {
        message.error(error.data.message);
      }
    }
  
    public async loadFile(file: IFile, id: any) {
      try {
        await presignedUploaderService.upload(
          file,
          (params: any) => getUploadProjectUrlReq(params),
          (params: any) => finishUploadProjectReq(params),
          { projectId: id }
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  }
  
  export const projectService = new ProjectService();
  