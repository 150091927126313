import { IProjectBlock } from '@/typing/interfaces/domain/project-block.interface'
import { Card, message } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import style from "./style.module.css";
import { UserRole } from "@/typing/enums/users-role.enum";
import {
  deleteContentsReq,
  finishUploadFileContent,
  getLinkFileContent,
  storeContentsReq,
} from "@/api/projects";
import { sectAccount } from "@/store/account/selectors";
import { useSelector } from "react-redux";
import { ContentBlockTextList } from "../../atoms/content-block-text-list";
import { ContentBlockFooter } from "../../atoms/content-block-footer";
import { ContentBlockHeader } from "../../atoms/content-block-header";
import { getModeViewBlock, getViewLookBlock } from "@/store/shared/selectors";
import _ from "lodash";
import {
  IMAGE_FILE_TYPES,
  presignedUploaderService,
} from "@/services/system/file.service";
import { GetLinkContenpFileParams } from "@/api/projects/interfaces";
import { geTypeFileContent, getFileType } from "../../helpers";

interface IProps {
  info: IProjectBlock;
  removeBlock?: (e: React.MouseEvent, id: number) => void;
  editBlock?: (e: React.MouseEvent, info: IProjectBlock) => void;
  accountRole: string;
  loadProject: () => void;
  stylesCard?: CSSProperties;
}

export const ContentBlock = ({
  info,
  removeBlock,
  editBlock,
  accountRole,
  loadProject,
  stylesCard,
}: IProps) => {
  const allowedRoles = info?.allowedRoles?.sort();

  const [messageApi, contextHolder] = message.useMessage();
  const [valueTextarea, setValueTextarea] = useState<string>("");
  const [hideToolbar, setHideToolbar] = useState<boolean>(false);
  const [editorHeight, setEditorHeight] = useState<number>(64);
  const [files, setFiles] = useState<File[]>([]);

  const { data: account } = useSelector(sectAccount);
  const isModeViewBlock = useSelector(getModeViewBlock);
  const viewLookBlock = useSelector(getViewLookBlock);

  const errorError = (error: string) => {
    messageApi.open({
      type: "error",
      content: `Error: ${error}`,
    });
  };

  const createFileContent = async (file: File, contentId: number) => {
    try {
      const contentType = geTypeFileContent(file.name);

      await presignedUploaderService.upload(
        file,
        (params: any) => getLinkFileContent(params),
        (params: any) => finishUploadFileContent(params),
        {
          id: contentId,
          type: contentType,
        }
      );
    } catch (error) {}
  };

  const generateContent = () => {
    return !_.isEmpty(files) && !valueTextarea ? "Files:" : valueTextarea;
  };



  const loadFilesContent = async (id: number) => {
    await Promise.all(
      files.map(async (it) => {
        await createFileContent(it, id);
      })
    );
    hideToolbar !== null && setHideToolbar(true);
    loadProject();
  };

  const contentCreate = async () => {
    try {
      if (!valueTextarea.trim() && _.isEmpty(files)) return;

      const newContent = {
        type: "text",
        content: generateContent(),
        contentBlockId: info.id,
        createAt: new Date().toISOString().split("T")[0],
        createBy: account.name,
      };
      const { data: id } = await storeContentsReq(newContent);
      if (!_.isEmpty(files) && id) {
        await loadFilesContent(id);
      }
      setValueTextarea("");
      setFiles([]);
      loadProject();
      hideToolbar !== null && setHideToolbar(true);
    } catch (error) {
      errorError(error.message);
    } finally {
      setEditorHeight(64);
    }
  };

  const contentDelete = async (id: number) => {
    try {
      await deleteContentsReq(id);
      loadProject();
    } catch (error) {
      errorError(error.message);
    }
  };

  const toolbarOnClick = () => {
    setHideToolbar(!hideToolbar);
    if (hideToolbar === null) {
      setEditorHeight(235);
    } else if (hideToolbar) {
      setEditorHeight(235);
    } else {
      setEditorHeight(64);
    }
  };

  const copyContent = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    isModeViewBlock && setHideToolbar(true);
  }, [isModeViewBlock]);

  return (
    <Card
      style={stylesCard}
      className={
        isModeViewBlock ? style.card : `${style.card} ${style.notActiveCard}`
      }
      title={
        <ContentBlockHeader
          isModeViewBlock={isModeViewBlock}
          info={info}
          allowedRoles={allowedRoles}
          accountRole={accountRole}
          editBlock={editBlock}
          removeBlock={removeBlock}
        />
      }
      bordered={false}
      bodyStyle={{
        height: viewLookBlock === "list" ? "300px" : "75vh",
        padding: 0,
      }}
    >
      <div className={style.cardContainer}>
        {contextHolder}
        <ContentBlockTextList
          info={info}
          accountRole={accountRole}
          contentDelete={contentDelete}
          copyContent={copyContent}
        />
        {accountRole !== UserRole.User && isModeViewBlock ? (
          <ContentBlockFooter
            files={files}
            selectFiles={setFiles}
            editorHeight={editorHeight}
            hideToolbar={hideToolbar}
            setValueTextarea={setValueTextarea}
            toolbarOnClick={toolbarOnClick}
            valueTextarea={valueTextarea}
            contentCreate={contentCreate}
            viewLookBlock={viewLookBlock}
          />
        ) : null}
      </div>
    </Card>
  );
};
