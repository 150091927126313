import React from 'react'
import { FileUploader } from 'react-drag-drop-files'
import AddPhoto from '@/assets/img/account/add-photo.svg'
import styles from './styles.module.css'

interface IProps {
  width: number
  height: number
  IndividualStyles?: React.CSSProperties
  urlPhoto?: (file: any) => void
  setUrlPhoto?: (value: string) => void
}

interface IObjectFile {
  [index: number]: File
  length: number
}

export const UploadPhoto = ({
  width,
  height,
  IndividualStyles,
  urlPhoto,
  setUrlPhoto
}: IProps) => {
  const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG']


  const handleChange = (file: IObjectFile) => {
    const url = file && URL.createObjectURL(file[0]) 
    urlPhoto(file[0])
    setUrlPhoto(url)
  }

  return (
    <FileUploader
      multiple={true}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
    >
      <div
        className={styles.container}
        style={{
          width: width,
          height: height,
          ...IndividualStyles,
        }}
      >
        <img className={styles.images} src={AddPhoto} alt="addPhoto" />
        <p className={styles.text}>Photo upload</p>
        <p className={styles.text}>Drag & drop any photo here</p>
        <p className={styles.text}>JPEG, PNG, GIF, JPG</p>
      </div>
    </FileUploader>
  )
}
