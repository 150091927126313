import React from 'react'
import { createStyleSheet } from '@/core/helpers/style.helper'

interface IProps {
  text: string
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  individualStyle?: React.CSSProperties
}

export const PrimaryButtonForm = ({
  text,
  type,
  disabled,
  onClick,
  individualStyle,
}: IProps) => {
  const styles = createStyleSheet({
    buttonStyle: {
      color: '#fff',
      backgroundColor: '#131212',
      border: '1px #131212 solid',
      transition: 'all 0.3s ease 0s',
      fontSize: '16px',
      lineHeight: '140%',
      letterSpacing: '1.2px', 
      padding: ' 11px 90px',
      borderRadius: '24px',
      fontWeight: '300',
      ...individualStyle,
    },
  })

  return (
    <button
      style={styles.buttonStyle}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
