import { UserPosition} from './../../../typing/enums/user-position.enum';


export const userPositionConfig = { 
    [UserPosition.Admin]: 'Admin',
    [UserPosition.Designer]: 'Designer',
    [UserPosition.Developer]: 'Developer',
    [UserPosition.Manager]: 'Manager',
    [UserPosition.QA]: 'Tester',
}

