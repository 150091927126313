import React from "react";
import { usersRouterName } from "@/modules/users/config/routers.config";
import { projectsRouterName } from "@/modules/projects/config";

export const navListConfig = [
  {
    id: 1,
    prefix: <i className="fa-regular fa-folder-closed" />,
    route: projectsRouterName,
    title: "All projects",
  },
  {
    id: 2,
    prefix: <i className="fa-regular fa-user" />,
    route: usersRouterName,
    title: "Users",
  },
];
