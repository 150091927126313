import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/img/header/search-icon.svg";
import style from "./style.module.css";

interface IProps {
	value: string;
	onChange: (value: string) => void;
	styles?: CSSProperties;
}
export const SearchInput: FC<IProps> = ({ onChange, value, styles }) => {
	const [input, setInput] = useState("");
	const timmerRef = useRef(null);

	useEffect(() => {
		setInput(value);
	}, [value]);

	useEffect(() => {
		if (timmerRef.current) clearTimeout(timmerRef.current);

		timmerRef.current = setTimeout(() => {
			if (value !== input) onChange(input);
		}, 500);

		return () => clearTimeout(timmerRef.current);
	}, [input]);

	return (
		<div style={styles} className={style.searchWrapper}>
			<div className={style.search}>
				<SearchIcon />
				<input
					onChange={(e) => onChange(e.target.value)}
					type="text"
					placeholder={"Search"}
				/>
			</div>
		</div>
	);
};
