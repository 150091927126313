import React from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import MarkdownIt from "markdown-it";
import mila from "markdown-it-link-attributes";
import ReactMarkdown from "react-markdown";

interface IProps {
	height?: number;
	value: string;
	onChange?: (value: string) => void;
	hideToolbar?: boolean;
	preview?: "live" | "edit" | "preview";
	placeholder?: string;
}

export const EditorMarkdown = ({
	preview,
	hideToolbar,
	height,
	value,
	onChange,
	placeholder,
}: IProps) => {
	const mdParser = new MarkdownIt();

	mdParser.use(mila, {
		attrs: {
			target: "_blank",
			rel: "noopener",
		},
	});

	const parser = new DOMParser();

	return (
		<div data-color-mode="light">
			<MarkdownEditor
				hideToolbar={hideToolbar}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};
