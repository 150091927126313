import { List } from "antd";
import style from "./style.module.css";
import React from "react";
import { ReactComponent as EditIcon } from "@/assets/img/project/pencil.svg";
import { ReactComponent as DeleteIcon } from "@/assets/img/project/trash.svg";
import { ITesting } from "@/typing/interfaces/domain/testing.interface";
import { ITestingSection } from "@/typing/interfaces/domain/testing-section.interface";
import { SectionTest } from "../section-test";

interface IProps {
  list: ITestingSection;
  editSectionTesting: (data: ITestingSection) => void;
  openPopupDelete: (e: any, id: number) => void;
  onClickCreateTest: (id: number) => void;
  onDetailTesting: () => void;
  onTestingData: (item: ITesting) => void;
  onSetSectionName: (val: string) => void;
}

export const Section = ({
  list,
  editSectionTesting,
  openPopupDelete,
  onClickCreateTest,
  onDetailTesting,
  onTestingData,
  onSetSectionName,
}: IProps) => {
  return (
    <List.Item
      className={style.itemLink}
      style={{
        width: "100%",
        borderRadius: "12px",
        padding: "16px 6px 16px 16px",
      }}
    >
      <div className={style.itemContainer}>
        <div className={style.sectionHeader}>
          <div className={style.sectionHeaderGroup}>
            <div className={style.sectionName}>{list?.name}</div>
            <div className={style.sectionID}>ID: {list?.id}</div>
          </div>
          <div className={style.actionButtonGroup}>
            <EditIcon
              className={style.projectIcon}
              onClick={() => editSectionTesting(list)}
            />
            <DeleteIcon
              className={style.projectIcon}
              onClick={(e) => openPopupDelete(e, list.id)}
            />
          </div>
        </div>
        <div className={style.contentContainer}>
          {list?.description && (
            <div className={style.sectionDescription}>
              Description:
              <div className={style.descriptionText}>{list?.description}</div>
            </div>
          )}
          <div className={style.testList}>
            {list.testCases
              .sort((a, b) => a.id - b.id)
              .map((it: ITesting, index: number) => {
                return (
                  <SectionTest
                    testNumber={index + 1}
                    key={it.id}
                    onDetailTesting={onDetailTesting}
                    onTestingData={onTestingData}
                    onSetSectionName={() => onSetSectionName(list.name)}
                    item={it}
                  />
                );
              })}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <button
            className={style.creteTestBtn}
            onClick={() => onClickCreateTest(list.id)}
          >
            <span className={style.plus}>+</span>{" "}
            <span className={style.text}>Add new test</span>
          </button>
        </div>
      </div>
    </List.Item>
  );
};
