import { TestingAutomationType } from '@/typing/enums/testing-automation-type.enum'
import { TestingPriority } from '@/typing/enums/testing-priority.enum'
import { TestingType } from '@/typing/enums/testing-type.enum'

export const optionsPriority = [
  { label: 'Critical', value: TestingPriority.Critical },
  { label: 'High', value: TestingPriority.High },
  { label: 'Medium', value: TestingPriority.Medium },
  { label: 'Low', value: TestingPriority.Low },
]
export const optionsType = [
  { label: 'Functional', value: TestingType.Functional },
  { label: 'Security', value: TestingType.Security },
  { label: 'Performance', value: TestingType.Performance },
  { label: 'Usability', value: TestingType.Usability },
  { label: 'Compatibility', value: TestingType.Compatibility },
  { label: 'Accessibility', value: TestingType.Accessibility },
]
export const optionsAutomationType = [
  { label: 'Cypress', value: TestingAutomationType.Cypress },
  { label: 'Appium', value: TestingAutomationType.Appium },
]
