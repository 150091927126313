import { deleteWikiRequest, getWikiListRequest } from "@/api/wiki";
import { useEventsListener, usePaginationList } from "@/core/hooks";
import { IWiki } from "@/typing/interfaces/domain/wiki.interface";
import { List, ConfigProvider, Pagination } from "antd";
import _ from "lodash";
import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { EmptyWiki } from "../empty-wiki";
import { ReactComponent as DeleteIcon } from "./../../../../assets/img/users/delete.svg";
import { ReactComponent as FileIcon } from "./../../../../assets/img/wiki/file.svg";
import style from "./style.module.css";
import { ConfirmPopup, PaginationArrow } from "@/core/components";
import { FormDrawerWiki } from "../../smart-components";
import { appEvents } from "@/core/events";

interface IProps {
	items: IWiki[];
	onResetWiki: () => void;
	isLoading: boolean;
	page: number;
	count: number;
	limit: number;
	onloadPage: (page: number) => void;
	onSetLoadParams: (limit: number) => void;
	onOpenDetail: () => void;
	onWikiId: (id: number) => void;
	isOpenDrawer: boolean;
	onOpenDrawer: () => void;
}

export const WikiList: FC<IProps> = ({
	items,
	isLoading,
	page,
	count,
	limit,
	onloadPage,
	onSetLoadParams,
	onResetWiki,
	onOpenDetail,
	onWikiId,
	isOpenDrawer,
	onOpenDrawer,
}) => {
	const { id: projectId } = useParams();

	const [popupDelete, setPopupDelete] = useState({
		isShow: false,
		id: null,
	});

	const onDeleteWiki = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		try {
			await deleteWikiRequest(popupDelete.id);
			onResetWiki();
			setPopupDelete({
				isShow: false,
				id: null,
			});
		} catch (error) {
			console.log(error);
			setPopupDelete({
				isShow: false,
				id: null,
			});
		}
	};

	return (
		<div className={style.wrapper}>
			{!_.isEmpty(items) && !isLoading ? (
				<>
					<List
						size="large"
						grid={{ gutter: 10, column: 1 }}
						header={
							<div className={style.wikiHeader}>
								<p>Pages</p>
							</div>
						}
						bordered={false}
						className={style.wikiList}
						dataSource={items}
						renderItem={(it) => (
							<List.Item
								className={style.itemLink}
								style={{
									width: "100%",
									borderRadius: 32,
									height: 56,
									padding: 16,
									border: "1px solid #B6B6B6",
									background: "#FFF",
									cursor: "pointer",
								}}>
								<div
									onClick={() => {
										onWikiId(it.id);
										onOpenDetail();
										appEvents.emit("openWiki", {
											title: "Edit Page",
										});
									}}
									className={style.itemContainer}>
									<div className={style.projectName}>
										<FileIcon />
										{it.title}
									</div>

									<span
										className={style.icon}
										onClick={(e) => {
											e.stopPropagation();
											setPopupDelete({
												id: it.id,
												isShow: true,
											});
										}}>
										<DeleteIcon width={22} height={24} />
									</span>
								</div>
							</List.Item>
						)}
					/>
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: "#686868",
							},
						}}>
						<div className={style.paginationWrapper}>
							<Pagination
								current={page}
								total={count}
								pageSize={limit}
								onChange={(page) => onloadPage(page)}
								onShowSizeChange={(_, size: number) => onSetLoadParams(size)}
								className={style.pagination}
								showSizeChanger={true}
								itemRender={PaginationArrow}
							/>
						</div>
					</ConfigProvider>
					<ConfirmPopup
						title={`
						Delete this Page?`}
						popupActive={popupDelete}
						onClose={setPopupDelete}
						onConfirm={onDeleteWiki}
					/>
				</>
			) : (
				<EmptyWiki
					onResetWiki={onResetWiki}
					projectId={projectId}
					conditionLoader={!isLoading}
				/>
			)}
			<FormDrawerWiki onResetWiki={onResetWiki} isOpen={isOpenDrawer} onPressClose={onOpenDrawer} />
		</div>
	);
};
