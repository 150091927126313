import React, { FC, ReactNode } from "react";
import styles from "./style.module.css";
import {
	IconsViewBlockSmart,
	SwitchBlockTab,
} from "../../smart-components/index";
import { Button } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/img/header/search-icon.svg";
import style from "./style.module.css";
import { appEvents } from "@/core/events";

interface IProps {
	button: ReactNode;
	value?: string;
	onChange?: (value: string) => void;
}

export const TabRightContent: FC<IProps> = ({ button }) => {
	return (
		<div className={styles.wrapper}>
			<Button
				onClick={() =>
					appEvents.emit("openBlockPopup", {
						isOpen: true,
					})
				}
				className={style.searchButton}>
				<SearchIcon />
			</Button>
			{button}
			<IconsViewBlockSmart />
			<SwitchBlockTab />
		</div>
	);
};
