import { IProject } from "@/typing/interfaces/domain/project.interface";
import React, { FC } from "react";
import { ReactComponent as StarIcon } from "@/assets/img/project/star.svg";
import { firstLetter, getTextPreview } from "@/core/helpers";
import style from "./style.module.css";
import { favoriteProjectService } from "@/services/domain";

interface IProps {
	project: IProject;
	isFavorite: boolean;
}

export const TabLeftInfoProject: FC<IProps> = ({ project, isFavorite }) => {
	return (
		<div className={style.buttons}>
			{project?.imageUrl ? (
				<img
					className={style.image}
					src={String(project?.imageUrl)}
					alt="avatar-user"
				/>
			) : (
				<p className={style.projectTopLetter}>
					{firstLetter(project?.name || "Image")}
				</p>
			)}
			<span className={style.projectName}>
				{getTextPreview(project?.name, 15)}
			</span>
			<span
				onClick={() =>
					favoriteProjectService.toggleFavoriteProject(project?.id)
				}
				className={style.starWrapper}>
				<StarIcon className={isFavorite ? null : style.startIconActive} />
			</span>
		</div>
	);
};
