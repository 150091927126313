import { Tabs } from "antd";
import React, { ReactNode, useContext } from "react";
import { projectTabsConfig } from "../../config/project-tabs.config";
import { ProjectTabsEnum } from "../../../../typing/enums/project-tabs.enum";
import { IProjectBlock } from "@/typing/interfaces/domain/project-block.interface";
import { IProject } from "@/typing/interfaces/domain/project.interface";
import { IContext } from "@/typing/interfaces/domain/context.interface";
import { Context } from "@/modules/root/components/context";

interface IProps {
  onChangeKey: (key: ProjectTabsEnum) => void;
  reload: () => void;
  addBlock: boolean;
  setAddBlock: (data: boolean) => void;
  projectBlocks: IProjectBlock[];
  projectInfo: IProject;
  onClickKeyTab: (key: any) => void;
  tabBarExtraContent?: {
    left?: ReactNode;
    right?: ReactNode;
  };
}

export const ProjectTabs = ({
  onChangeKey,
  reload,
  projectBlocks,
  addBlock,
  setAddBlock,
  projectInfo,

  onClickKeyTab,
  tabBarExtraContent,
}: IProps) => {
  const items = projectTabsConfig({
    reload,
    projectBlocks,
    addBlock,
    setAddBlock,
    projectInfo,
    onChangeKey,
    onClickKeyTab,
  });

  const { activeTab }: IContext = useContext(Context);

  return (
    <div>
      <Tabs
        size="large"
        defaultActiveKey={ProjectTabsEnum.Block}
        activeKey={activeTab}
        items={items}
        onChange={onChangeKey}
        onTabClick={(e) => onClickKeyTab(e)}
        tabBarExtraContent={tabBarExtraContent}
      />
    </div>
  );
};
