import { ConfigProvider, Select } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'
import './style.css'
const { Option } = Select
interface IProps {
  renderOptions?: () => void
  dataEdit?: any
  control: any
  register: any
  name: string
  disabled?: boolean
  size?: 'large' | 'middle' | 'small'
  defaultTitle?: string
}
export const SelectControl = ({
  size = 'large',
  name,
  control,
  disabled = false,
  dataEdit,
  register,
  renderOptions,
  defaultTitle="account",
}: IProps) => {
  return (
    <Controller
      control={control}
      defaultValue={dataEdit}
      name={name}
      render={({ field }) => (
        <ConfigProvider
          theme={{
            token: {
              fontWeightStrong: 300,
              fontSize: 16,
              colorPrimary: '#686868',
              colorText: '#131212',
              colorIcon: '#ffffff',
            },
          }}
        >
          <Select
            size={size}
            disabled={disabled}
            popupClassName="options"
            className="containerSelectController"
            bordered={false}
            showArrow={false}
            {...field}
            style={{ width: '100%', fontSize: '16px'}}
          >
            <>{renderOptions && renderOptions()}</>
            <Option
              register={register}
              className="optionDisable"
              id={field.name}
              onChange={(e: any) => field.onChange(e.value)}
              disabled={true}
            >
              <p style={{ color: '#c4c4c4', fontSize: '16px', lineHeight: '240%' , fontWeight:'400'}}>
                {`Select ${defaultTitle}`}
              </p>
            </Option>
          </Select>
        </ConfigProvider>
      )}
    />
  )
}