import { ITesting } from "@/typing/interfaces/domain/testing.interface";
import { List, message } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import style from "./style.module.css";
import { ConfirmPopup } from "@/core/components/pop-up/confirm-popup";
import { EmptyList } from "@/core/components/empty-list";
import {
  deleteTestSectionReq,
  getTestSectionReq,
  patchTestSectionReq,
  postTestReq,
  postTestSectionReq,
} from "@/api/testing";
import { ITestingSection } from "@/typing/interfaces/domain/testing-section.interface";
import { Section } from "../../components/section";
import { TestingDetailedInfo } from "../../components/testing-detailed-info";
import { FormSelection } from "../../components/form-selection";
import { useEventsListener } from "@/core/hooks";

export const SectionsListPage = () => {
  const { id: projectId } = useParams();
  const [mode, setMode] = useState<"edit" | "create">(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [popupDelete, setPopupDelete] = useState({ isShow: false, id: null });
  const [createMode, setCreateMode] = useState<"section" | "test">("section");
  const [sectionId, setSectionId] = useState<number>(null);
  const [sectionData, setSectionData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [testingData, setTestingData] = useState<ITesting>(null);
  const [sectionName, setSectionName] = useState<string>("");
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const goDetailedTest = () => {
    setOpenDetail(true);
  };

  useEventsListener(
    "openTest",
    (data) => {
      setOpenDrawer(data.isOpen);
      setCreateMode(data.key);
      setMode(data.mode);
      if (data.key === "section") {
        setOpenDetail(false);
      }
      if (data?.reload) {
        getSection();
      }
    },
    []
  );
  const errorError = (error: string) => {
    messageApi.open({
      type: "error",
      content: `Error: ${error}`,
    });
  };

  const successfully = (message: string) => {
    messageApi.open({
      type: "success",
      content: `${message}`,
    });
  };

  useEffect(() => {
    getSection();
  }, [projectId]);

  const getSection = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTestSectionReq({ projectId: +projectId });

      setSectionData(data.sort((a, b) => a.id - b.id));
    } catch (error) {
      const message = await error.data?.message.toString();
      errorError(message || error?.message);
    }
    setIsLoading(false);
  };

  const editSection = async (newData: ITestingSection) => {
    try {
      await patchTestSectionReq(_.pick(newData, ["id", "name", "description"]));
    } catch (error) {
      const message = await error.data?.message.toString();
      errorError(message || error?.message);
    }
    getSection();
  };

  const reloadSection = async (payload: ITesting) => {
    setTestingData(payload);
    await getSection();
  };
  const createSection = async (newData: any) => {
    try {
      await postTestSectionReq({ ...newData, projectId: +projectId });
    } catch (error) {
      const message = await error.data?.message.toString();
      errorError(message || error?.message);
    }
    getSection();
  };

  const onRemoveSection = async () => {
    try {
      await deleteTestSectionReq({ id: sectionId });
    } catch (error) {
      const message = await error.data?.message.toString();
      errorError(message || error?.message);
    }
    getSection();
    setPopupDelete({
      isShow: false,
      id: null,
    });
  };

  const onClickEditSection = (newData: ITestingSection) => {
    setOpenDrawer(true);
    setDataEdit(newData);
    setMode("edit");
  };

  const openPopupDelete = (e: any, id: number) => {
    setSectionId(id);
    setPopupDelete({ isShow: true, id: id });
  };

  const onClickCreateTest = (sectionId: number) => {
    setSectionId(sectionId);
    setCreateMode("test");
    setOpenDrawer(true);
    setMode("create");
  };

  const createTest = async (newData: ITesting) => {
    try {
      await postTestReq({ ...newData });
      successfully(`Test ${newData.name} created successfully`);
    } catch (error) {
      const message = await error.data?.message.toString();
      errorError(message || error?.message);
    }
    getSection();
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDataEdit(null);
    setCreateMode("section");
  };

  const sectionsList = useMemo(() => {
    if (isLoading) {
      return (
        <EmptyList
          conditionLoader={!isLoading}
          errorText={"The test section has not been created."}
        />
      );
    }
    return (
      <List
        size="large"
        grid={{ gutter: 20, column: 2 }}
        header={<div className={style.testingHeader}>Testing</div>}
        bordered={false}
        className={style.sectionList}
        dataSource={sectionData}
        renderItem={(list) => (
          <Section
            onDetailTesting={goDetailedTest}
            list={list}
            editSectionTesting={onClickEditSection}
            openPopupDelete={openPopupDelete}
            onClickCreateTest={onClickCreateTest}
            onSetSectionName={setSectionName}
            onTestingData={setTestingData}
          />
        )}
      />
    );
  }, [isLoading, sectionData]);

  return (
    <div className={style.wrapper}>
      {contextHolder}
      {openDetail ? (
        <TestingDetailedInfo
          createTest={createTest}
          testing={testingData}
          setTesting={reloadSection}
          sectionName={sectionName}
        />
      ) : (
        sectionsList
      )}

      <FormSelection
        sectionId={sectionId}
        mode={mode}
        openDrawer={openDrawer}
        createMode={createMode}
        onClose={onCloseDrawer}
        createSection={createSection}
        editSection={editSection}
        createTest={createTest}
        dataEdit={dataEdit}
      />

      <ConfirmPopup
        title={`Are you sure
        Delete this test section with all test cases?`}
        popupActive={popupDelete}
        onClose={setPopupDelete}
        onConfirm={onRemoveSection}
      />
    </div>
  );
};
