import { createStyleSheet } from "@/core/helpers/style.helper";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { FC } from "react";
import style from "./style.module.css";
interface IProps {
  onShowSideBar: () => void;
  isOpenSideBar: boolean;
}

export const RoundSideBarButton: FC<IProps> = ({
  onShowSideBar,
  isOpenSideBar,
}) => {
  return (
    <Button
      className={isOpenSideBar ? style.right : style.left}
      icon={<LeftCircleOutlined />}
      type="default"
      onClick={onShowSideBar}
    />
  );
};

const styles = createStyleSheet({
  container: {
    border: "none",
    borderRadius: 100,
  },
});
