import { ITesting } from "@/typing/interfaces/domain/testing.interface";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as TrashIcon } from "@/assets/img/project/trash.svg";
import { ReactComponent as PencilIcon } from "@/assets/img/project/pencil.svg";
import { deleteTestReq, patchTestReq } from "@/api/testing";
import { TestingForm } from "../testing-form";
import { ConfirmPopup } from "@/core/components";
import { appEvents } from "@/core/events";

interface IProps {
  testing: ITesting;
  sectionName?: string;
  createTest: (data: ITesting) => void;
  setTesting: (data: ITesting) => void;
}

export const TestingDetailedInfo = ({
  testing,
  sectionName,
  createTest,
  setTesting,
}: IProps) => {
  const [popupDelete, setPopupDelete] = useState({ isShow: false, id: null });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<ITesting>(null);

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDataEdit(null);
  };

  const openPopupDelete = (id: number) => {
    setPopupDelete({ isShow: true, id: id });
  };

  const onRemoveTesting = async () => {
    try {
      await deleteTestReq({ id: +testing.id });
      appEvents.emit("openTest", {
        key: "section",
        isOpen: false,
        mode: "create",
        reload: true,
      });
    } catch (error) {
      console.error("On Remove Test", error);
    }
    setPopupDelete({
      isShow: false,
      id: null,
    });
  };

  const onClickEditTest = (e: any, info: ITesting) => {
    e.preventDefault();
    setOpenDrawer(true);

    setDataEdit(info);
  };

  const editTest = async (newData: ITesting) => {
    try {
      const { data } = await patchTestReq(newData);
      setTesting(data);
    } catch (error) {
      console.error("Edit Test", error);
    }
  };

  return (
    <>
      <h2 className={styles.title}>
        Section {sectionName}. Test {testing.name}
      </h2>
      <div className={styles.container}>
        <div className={styles.wrapperHeader}>
          <div className={styles.headerTiles}>
            <div className={styles.headerTitle}>
              <span className={styles.textLabel}>Test type:</span>
              <span className={styles.titleLabel}>{testing.testCaseType}</span>
            </div>
            <div className={styles.headerTitle}>
              <span className={styles.textLabel}>Automation type:</span>
              <span className={styles.titleLabel}>
                {testing.automationType}
              </span>
            </div>
          </div>
          <div className={styles.wrapperIcons}>
            <span
              className={styles.icon}
              onClick={(e) => {
                onClickEditTest(e, testing);
              }}
            >
              <PencilIcon />
            </span>
            <span
              className={styles.icon}
              onClick={(e) => {
                openPopupDelete(testing.id);
              }}
            >
              <TrashIcon />
            </span>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.mainContent}>
            <h4 className={styles.mainTitle}>Preconditions</h4>
            <p className={styles.mainText}>{testing.preconditions}</p>
          </div>
          <div className={styles.mainContent}>
            <h4 className={styles.mainTitle}>Steps</h4>
            <p className={styles.mainText}>{testing.steps}</p>
          </div>
          <div className={styles.mainContent}>
            <h4 className={styles.mainTitle}>Expected Result</h4>
            <p className={styles.mainText}>{testing.expectedResult}</p>
          </div>
        </div>
      </div>
      <TestingForm
        openDrawer={openDrawer}
        modeForm={"edit"}
        onClose={onCloseDrawer}
        onSaveEdit={editTest}
        dataEdit={dataEdit}
        sectionTitle={sectionName}
        sectionId={testing?.sectionId}
      />
      <ConfirmPopup
        title={`
        Delete test`}
        popupActive={popupDelete}
        onClose={setPopupDelete}
        onConfirm={onRemoveTesting}
      />
    </>
  );
};
