import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { accountService } from "@/services/domain/account.services";
import { Loader } from "@/core/components/loader";
import { selectNavGroup } from "@/store/navigation";
import { NavGroupKey } from "@/typing/enums";
import { ContextProvider } from "./components/context";
import { passwordChange } from "../auth/config";
import { authService } from "@/services/domain/auth.services";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routersAuth } from "./components/auth-routes/routers.config";
import { AuthRoutesWrapper } from "./components/auth-routes/auth-routes-wrapper";
import { PrivateRoutesWrapper } from "./components/private-routes/private-routes-wrapper";
import { routersPrivate } from "./components/private-routes/routers.config";
import { routersPublic } from "./components/public-routes/routers.config";

export const CRouter = () => {
  const location = window.location.pathname;
  const navKey = useSelector(selectNavGroup);

  useEffect(() => {
    if (location === passwordChange) {
      authService.navigateToAuth();
    } else {
      accountService.init();
    }
  }, []);

  if (navKey === NavGroupKey.Loading) {
    return <Loader />;
  }
  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthRoutesWrapper />}>
            {routersAuth.map((it: any) => (
              <Route path={it.path} key={it.path} element={it.element} />
            ))}
          </Route>
          <Route element={<PrivateRoutesWrapper />}>
            {routersPrivate.map((it: any) => (
              <Route path={it.path} key={it.path} element={it.element} />
            ))}
          </Route>
          <Route>
            {routersPublic.map((it: any) => (
              <Route path={it.path} key={it.path} element={it.element} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  );
};



