import React, { FC, useEffect, useState } from 'react'
import { UploadPhoto } from '@/core/components/upload-photo'
import { ReactComponent as CloseIcon } from '@/assets/img/account/close-form.svg'
import { ReactComponent as EditIcon } from '@/assets/img/account/Edit.svg'
import style from './styles.module.css'
import { PrimaryButton } from '@/core/components'
import { accountService } from "@/services/domain/account.services";

interface IProps {
  accountId: number;
  avatarUrl?: string;
  success?: (value: string) => void;
}

export const UploadImage: FC<IProps> = ({ accountId, avatarUrl, success }) => {
  const [fileImage, setFileImage] = useState(null);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [urlPhoto, setUrlPhoto] = useState<string>(null);

  const handleUpload = (file: string) => {
    setShowButton(true);
    setFileImage(file);
  };

  useEffect(() => {
    fileImage && setShowButton(true);
  }, [fileImage]);

  const onSubmitPhoto = async () => {
    try {
      await accountService.uploadAvatar(fileImage, accountId);
      success("Photo uploaded successfully");
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setShowButton(false);
    }
  };

  const cancelUploadPhoto = () => {
    setShowButton(false);
    setFileImage(null);
  };

  if (!avatarUrl && !fileImage) {
    return (
      <div className={style.containerImage}>
        <UploadPhoto
          width={142}
          height={142}
          setUrlPhoto={setUrlPhoto}
          urlPhoto={handleUpload}
          IndividualStyles={{ borderRadius: "50%" }}
        />
      </div>
    );
  }

  return (
    <div className={style.box}>
      {avatarUrl ? (
        <div className={style.containerImage}>
          <div className={style.boxImage}>
            <img
              className={style.images}
              src={fileImage ? urlPhoto : avatarUrl}
              alt="User"
            />
            {showButton && (
              <button
                className={style.deleteImgBtn}
                onClick={cancelUploadPhoto}
              >
                <CloseIcon className={style.deleteImgIcon} />
              </button>
            )}
            <button className={style.editImgBtn}>
              <UploadPhoto
                width={24}
                height={24}
                setUrlPhoto={setUrlPhoto}
                IndividualStyles={{
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                }}
                urlPhoto={handleUpload}
              />
              <EditIcon className={style.editIcon} />
            </button>
            {showButton && (
              <PrimaryButton
                text="Save avatar"
                individualStyle={{ opacity: 1 }}
                onClick={onSubmitPhoto}
              />
            )}
          </div>
        </div>
      ) : fileImage ? (
        <div className={style.containerImage}>
          <div className={style.boxImage}>
            <img className={style.images} src={urlPhoto} alt="User" />
            {showButton && (
              <>
                <button
                  className={style.deleteImgBtn}
                  onClick={cancelUploadPhoto}
                >
                  <CloseIcon className={style.deleteImgIcon} />
                </button>
                <PrimaryButton
                  text="Save avatar"
                  individualStyle={{ opacity: 1 }}
                  onClick={onSubmitPhoto}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        showButton && (
          <PrimaryButton
            text="Save avatar"
            individualStyle={{ opacity: 1 }}
            onClick={onSubmitPhoto}
          />
        )
      )}
    </div>
  );
};
