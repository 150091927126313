import React from "react";
import { Navigate } from "react-router-dom";
import { UsersPage } from "@/modules/users/pages/users-page";
import { ProjectsPage, ProjectDetailedPage } from "@/modules/projects/pages";
import {
  projectDetailedRouterName,
  projectsRoute,
  projectsRouterName,
} from "@/modules/projects/config";
import { accountRouterName } from "@/modules/account/config/routers.config";
import { AccountPage } from "@/modules/account/pages";
import {
  usersProfileRouter,
  usersRouter,
} from "@/modules/users/config/routers.config";
import { testingDetailedRouter } from "@/modules/testing/config/routers.config";
import { UserProfilePage } from "@/modules/users/pages/user-profile-page";

export const routersPrivate = [
  {
    path: accountRouterName,
    element: <AccountPage />,
  },
  {
    path: usersRouter,
    element: <UsersPage />,
  },
  {
    path: usersProfileRouter,
    element: <UserProfilePage />,
  },
  {
    path: projectsRouterName,
    element: <ProjectsPage />,
  },
  {
    path: projectDetailedRouterName,
    element: <ProjectDetailedPage />,
  },

  {
    path: "*",
    element: <Navigate to={projectsRouterName} />,
  },
];
