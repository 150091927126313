import React, { FC } from 'react'
import { Loader } from '@/core/components/loader'
import  style  from './style.module.css'

interface IProps {
  conditionLoader: boolean
  errorText: string
}

export const EmptyList: FC<IProps> = ({ conditionLoader, errorText }) => {
  return !conditionLoader ? (
    <Loader />
  ) : (
    <div className={style.inner}>
      <div className={style.box}>
        <p className={style.text}>{errorText}</p>
      </div>
    </div>
  )
}