import React from "react";
import { PaginationProps } from "antd";
import { ReactComponent as ArrowLeft } from "../../../../assets/img/project/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/img/project/arrow-right.svg";
import style from "./style.module.css";

export const PaginationArrow: PaginationProps["itemRender"] = (
	_,
	type,
	originalElement
) => {
	if (type === "prev") {
		return (
			<span className={style.arrowWrapper}>
				<ArrowLeft />
				<span className={style.text}>First</span>
			</span>
		);
	}
	if (type === "next") {
		return (
			<span className={style.arrowWrapper}>
				<span className={style.text}>Last</span>
				<ArrowRight />
			</span>
		);
	}
	return originalElement;
};
