import { ITestingSection } from '@/typing/interfaces/domain/testing-section.interface'
import { ITesting } from '@/typing/interfaces/domain/testing.interface'
import React from 'react'
import { TestingForm } from '../testing-form'
import { TestingSectionForm } from '../testing-section-form'

interface IProps {
  mode: string;
  openDrawer: boolean;
  createMode: string;
  onClose: () => void;
  createSection: (section: ITestingSection) => void;
  editSection: (section: ITestingSection) => void;
  createTest: (test: ITesting) => void;
  dataEdit?: any;
  sectionId: number;
}

export const FormSelection = ({
  mode,
  openDrawer,
  createMode,
  onClose,
  createSection,
  editSection,
  createTest,
  dataEdit,
  sectionId,
}: IProps) => {
  return (
    <>
      {createMode === "section" ? (
        <TestingSectionForm
          titleDrawer={mode}
          openDrawer={openDrawer}
          modeForm={mode}
          onClose={onClose}
          onSaveSection={createSection}
          onSaveEdit={editSection}
          dataEdit={dataEdit}
        />
      ) : (
        <TestingForm
          sectionId={sectionId}
          openDrawer={openDrawer}
          modeForm={mode}
          onClose={onClose}
          onSaveTest={createTest}
          dataEdit={dataEdit}
        />
      )}
    </>
  );
};
