import { combinePaths } from '@/core/helpers/router.helpers'

export const projectsBaseRoute = "/";
export const projectsRouterName = '/projects'
export const projectDetailedRouterName = '/project/:id'

export const projectsRoute = combinePaths(projectsBaseRoute, projectsRouterName)
export const projectDetailedRoute = combinePaths(
  projectsBaseRoute,
  projectDetailedRouterName
)
