import { Tooltip } from "antd";
import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import style from "./style.module.css";

interface IProps {
  isShowSideBar: boolean;
  splitLocation: string;
  prefix: ReactNode;
  title: string;
  route: string;
  classNameLabel?: string;
}

export const NavItem: FC<IProps> = ({
  isShowSideBar,
  splitLocation,
  title,
  route,
  prefix,
  classNameLabel,
}) => {
  const isActive = splitLocation === route.slice(1, route.length);
  return (
    <li className={style.item}>
      <Link to={route}>
        <div
          className={` ${
            !isShowSideBar
              ? `${style.wrapperContent} ${style.showContent}`
              : style.wrapperContent
          }`}
        >
          <Tooltip placement="right" title={title}>
            <div
              className={` ${style.wrapperLogo}  ${style.logo}  ${
                isActive ? style.active : ""
              } ${!isShowSideBar && style.wrapperLogoShow} `}
            >
              {prefix}
            </div>
          </Tooltip>
          <p
            className={`${classNameLabel} ${style.text} ${
              isActive ? style.active : ""
            } `}
          >
            {title}
          </p>
        </div>
      </Link>
    </li>
  );
};
