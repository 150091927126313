import { FileAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { FC, useRef } from "react";

interface IProps {
  onChange: (files: File[]) => void;
}
export const UploadFiles: FC<IProps> = ({ onChange }) => {
  const hiddenFileInput = useRef(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files;
    onChange(Array.from(fileUploaded));
  };

  const handleClick = (e: any) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <div>
        <input
          id="file"
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>

      <Button
        onClick={handleClick}
        style={{ border: "none", opacity: 0.8 }}
        icon={<FileAddOutlined style={{ fontSize: 20 }} />}
      />
    </>
  );
};
