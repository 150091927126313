import style from './style.module.css'
import React from "react";
import MarkdownPreview from '@uiw/react-markdown-preview'
import { IRecordContents, UserRole } from "@/typing";
import { ReactComponent as DeleteIcon } from '@/assets/img/project/trash.svg'
import { ReactComponent as CopyText } from '@/assets/img/project/copy.svg'
import moment from "moment";
import { FilesContent } from "../files-content";

interface IProps {
  contentDelete: (id: number) => void;
  copyContent: (text: string) => void;
  accountRole: string;
  item: IRecordContents;
}

export const ContentBlockTextItem = ({
  accountRole,
  item,
  contentDelete,
  copyContent,
}: IProps) => {
  const files = item?.files ? JSON.parse(item?.files) : [];
  return (
    <div key={item.id} className={style.cardItem}>
      <div className={style.item}>
        <div className={style.cardTextContainer}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MarkdownPreview
              style={{ opacity: 0.8, fontSize: 14 }}
              linkTarget="_blank"
              disableCopy
              source={item.content}
            />
            <div>{files ? <FilesContent files={files} /> : null}</div>
          </div>
          <div className={style.btnGroup}>
            <button
              className={style.cardItembtn}
              onClick={() => copyContent(item.content)}
            >
              <span className={style.btnTooltip}>Copy</span>
              <CopyText className={style.cardItemIcon} />
            </button>
            {accountRole !== UserRole.User && (
              <button
                className={style.cardItembtn}
                onClick={() => contentDelete(item.id)}
              >
                <span className={style.btnTooltip}>Delete</span>
                <DeleteIcon
                  className={`${style.cardItemIcon} ${style.iconDelete}`}
                />
              </button>
            )}
          </div>
        </div>

        <div className={style.itemDetails}>
          <div>{moment(item.createdAt).format("DD.MM.YYYY")}</div>
          <div>
            <span className={style.detailsName}>{item.createBy}</span>
            <span>{moment(item.createdAt).format("HH:mm")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

