import { PrimaryButton } from '@/core/components'
import style from './styles.module.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ConfigProvider, Input } from 'antd'
import { IChangePasswordPayload } from '@/api/auth/interfaces'

interface IProps {
  onSubmit: (data: IChangePasswordPayload) => void
  email: string
}

export const ChangePasswordEnterPassword = ({ onSubmit, email }: IProps) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = () => {
    if (!password) {
      setPasswordError('Password is required')
      return
    }
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters')
      return
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match')
      return
    }
    onSubmit({ email, password })
  }

  return (
    <div className={style.form}>
      <div className={style.login}>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorPrimaryHover: '#606060',
              },
            },
          }}
        >
          <div>
            <p className={style.inputName}>Password</p>
            <Input.Password
              className={` ${passwordError ? style.inputPasswordError : style.inputPassword}`}
              required
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter new password"
            />
            {passwordError && <span className={style.errorText}>{passwordError}</span>}
          </div>

          <div>
            <p className={style.inputName}>Confirm Password</p>
            <Input.Password
              className={` ${
                passwordError ? style.inputPasswordError : style.inputPassword
              }`}
              required
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Enter confirm password"
            />
            {passwordError && (
              <span className={style.errorText}>{passwordError}</span>
            )}
          </div>
        </ConfigProvider>
        <PrimaryButton onClick={handleSubmit} text="Set new Password"></PrimaryButton>
      </div>
      <Link className={style.link} to="/login">
        To Login →
      </Link>
    </div>
  )
}
