import { IWiki } from '@/typing'
import React, { FC } from 'react'
import styles from './style.module.css'
import { FormDrawerWiki } from '../../smart-components'
import MarkdownPreview from '@uiw/react-markdown-preview'

interface IProps {
  wiki: IWiki
  onResetWiki: () => void
  onLoadWiki: (id: number) => void
  isOpenDrawer: boolean
  onOpenDrawer: () => void
}

export const WikiDetail: FC<IProps> = ({
  wiki,
  onResetWiki,
  onLoadWiki,
  onOpenDrawer,
  isOpenDrawer,
}) => {
  return (
		<>
			<h4 className={styles.subTitle}>{wiki?.title}</h4>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.descriptionWrapper}>
						<MarkdownPreview
							className={styles.markdownPreview}
							source={wiki?.content}
						/>
					</div>
				</div>
			</div>
			<FormDrawerWiki
				existWiki={wiki}
				isOpen={isOpenDrawer}
				onPressClose={onOpenDrawer}
				onSuccessEdit={(id) => onLoadWiki(id)}
			/>
		</>
	);
}
