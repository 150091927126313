 const config = {
  //  API_URL: 'http://10.10.10.227:3001',
  //  API_URL: "https://jetstore-api.work-jetup.site/",
  API_URL: "http://localhost:3001/",
 };


console.log(process.env)
if (process.env.REACT_APP_HOST_TYPE === "local") {
  config.API_URL = 'http://localhost:3001/'
}

if (process.env.REACT_APP_HOST_TYPE === "dev") {
  config.API_URL = "https://vault-api.work-jetup.site/";
}

if (process.env.REACT_APP_HOST_TYPE === "prod") {
  config.API_URL = "https://vault-api.work-jetup.site/";
}

export { config };