import * as Yup from 'yup';

export const validationSchemaLogin = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('The email must be a valid email address.'),
  password: Yup.string()
    .required('No password provided.')
    .min(6, 'Password must be 6 or more characters')
    .max(20, 'Password Name must not exceed 20 characters')
    .matches(/^\S*$/, 'Whitespace is not allowed'),
});
