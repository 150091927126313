import React, { FC, useEffect, useState } from 'react'
import style from './style.module.css'
import { IProject } from '@/typing/interfaces/domain/project.interface'
import { getUsersProfileReq } from '@/api/users'
import { Loader } from '@/core/components'

interface IProps {
  projectInfo: IProject
}
export const ProjectInfo: FC<IProps> = ({ projectInfo }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [managerName, setManagerName] = useState(null)

  const getName = async (id:string) => {
    try {
      setIsLoading(true)
      const name: any = await getUsersProfileReq(+id)
      setManagerName(`${name.data.name + ' ' + name.data.lastName}`)
    } catch {
      console.error('get Manager info Error')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getName(projectInfo.manager)
  }, [])

  return !isLoading ? (
		<>
			<h2 className={style.titleName}>Information about {projectInfo.name}</h2>
			<div className={style.projectInfoWrapper}>
				<div className={style.content}>
					<div className={style.optionContainer}>
						<div className={style.itemUsers}>
							<p className={style.titleUsers}>Manager:</p>
							<div className={style.textBlockUser}>
								{managerName && managerName}
							</div>
						</div>
						<div className={style.itemUsers}>
							<p className={style.titleUsers}>Developers:</p>
							<div className={style.textBlockUser}>
								{projectInfo.developments.map((el) => (
									<div key={el.id}>{`${el.name + " " + el.lastName}`} </div>
								))}
							</div>
						</div>
						<div className={style.itemUsers}>
							<p className={style.titleUsers}>Testing:</p>
							<div className={style.textBlockUser}>
								{projectInfo.testing.map((el) => (
									<div key={el.id}>{`${el.name + " " + el.lastName}`}</div>
								))}
							</div>
						</div>
						<div className={style.itemUsers}>
							<p className={style.title}>Technologies:</p>
							<div className={style.textBlock}>{projectInfo.technologies}</div>
						</div>
						{/* {projectInfo.options && (
						<div className={style.itemUsers}>
							<p className={style.title}>Options:</p>
							<div className={style.textBlock}>{projectInfo.options}</div>
						</div>
					)} */}
					</div>
				</div>
				<div className={style.content}>
					<div className={style.descriptionContainer}>
						<p style={{ marginBottom: 12 }} className={style.title}>
							Description:
						</p>
						<div className={style.textBlock}>{projectInfo.description}</div>
					</div>
				</div>
			</div>
		</>
	) : (
		<Loader />
	);
}
