import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import { CRouter } from "./modules/root";
import "./assets/style/main.css";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
	return (
		<ConfigProvider>
			<Provider store={store}>
				<CRouter />
			</Provider>
		</ConfigProvider>
	);
}
export default App;
