import { PrimaryButton } from '@/core/components'
import style from './styles.module.css'
import React from 'react'
import { Link } from 'react-router-dom'
import AuthCode from 'react-auth-code-input'
import { ChangePasswordTimer } from '../change-password-timer'
import { loginRoute } from '../../config'

interface IProps {
  onChangeCode: (value: string) => void
  onSubmit: () => void
  timerRefresh: () => void
}

export const ChangePasswordEnterCode = ({ onChangeCode, onSubmit, timerRefresh }: IProps) => {
  return (
    <div className={style.form}>
      <div className={style.login}>
        <AuthCode
          length={4}
          inputClassName={style.inputForgotPassword}
          containerClassName={style.containerForgotPassword}
          allowedCharacters="numeric"
          onChange={(value) => onChangeCode(value)}
        />
        <p className={style.codeInputText}>A code has been sent to your phone</p>
        <PrimaryButton onClick={onSubmit} text="Send Code"></PrimaryButton>
        <ChangePasswordTimer timerRefresh={timerRefresh} />
      </div>

      <Link className={style.link} to={loginRoute}>
        To Login →
      </Link>
    </div>
  )
}
