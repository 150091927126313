import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import style from './style.module.css'
import { IProjectBlock, IRecordContents, UserRole } from '@/typing'
import { ContentBlockTextItem } from '../content-block-text-item'

interface IProps {
  contentDelete: (id: number) => void
  copyContent: (text: string) => void
  accountRole: string
  info: IProjectBlock
}

export const ContentBlockTextList = ({ accountRole, info, contentDelete, copyContent }: IProps) => {
	const content = info.recordContents.sort((a, b) => a.id - b.id);
	
  return (
    <div
      className={`${style.cardList} ${
        accountRole !== UserRole.User ? "" : style.cardListUser
      }`}
    >
      <div className={style.cardListContainer}>
        {content.map((item) => {
          return (
            <ContentBlockTextItem
              key={item.id}
              contentDelete={contentDelete}
              copyContent={copyContent}
              item={item}
              accountRole={accountRole}
            />
          );
        })}
      </div>
    </div>
  );
}
