import React, { FC } from "react";
import style from "./style.module.css";
import { Link, useLocation } from "react-router-dom";
import { authService } from "@/services/domain/auth.services";
import { useSelector } from "react-redux";
import { sectAccount } from "@/store/account/selectors";
import { getFavoriteProject } from "@/store/favorite-project/selectors";
import {
  AccountSideBar,
  ButtonExit,
  HeaderSideBar,
  ItemFavoriteProject,
  NavItem,
  RoundSideBarButton,
} from "./components";
import { navListConfig } from "./config";
import _ from "lodash";

interface IProps {
  showSideBar: boolean;
  onShowSideBar: () => void;
}

export const SideBar: FC<IProps> = ({ showSideBar, onShowSideBar }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { data: account } = useSelector(sectAccount);
  const projectsFavorites = useSelector(getFavoriteProject);

  const activeFavoriteProjectClass = (id: string) => {
    const isActive = splitLocation.includes(String(id));
    if (isActive) {
      return style.active;
    }
    return null;
  };

  return (
    <div className={style.mainContainer}>
      <HeaderSideBar isShowSideBar={showSideBar} />
      <div
        className={`${
          !showSideBar ? `${style.sidebar} ${style.showSideBar}` : style.sidebar
        }`}
      >
        <div style={{ position: "absolute", right: -15, top: "40vh" }}>
          <RoundSideBarButton
            isOpenSideBar={showSideBar}
            onShowSideBar={onShowSideBar}
          />
        </div>
        <div>
          <Link to={"/account"}>
            <AccountSideBar
              account={account}
              showSideBar={showSideBar}
              splitLocation={splitLocation[1]}
            />
          </Link>
        </div>
        <div className={style.container}>
          <ul className={style.tabs_navigation}>
            <li style={{ position: "relative" }} className={style.container}>
              {navListConfig.map((it) => {
                return (
                  <NavItem
                    classNameLabel={`${
                      showSideBar ? style.label_tab : style.label_tab_clossable
                    }
                   `}
                    key={it.id}
                    isShowSideBar={showSideBar}
                    splitLocation={splitLocation[1]}
                    prefix={it.prefix}
                    title={it.title}
                    route={it.route}
                  />
                );
              })}
            </li>
          </ul>
          <div className={style.projects_navigation}>
            <ul
              className={
                _.isEmpty(projectsFavorites) ? "" : style.favorite_projects
              }
            >
              {projectsFavorites.map((it) => {
                return (
                  <ItemFavoriteProject
                    classNameLabel={`${
                      showSideBar ? style.label_tab : style.label_tab_clossable
                    } ${activeFavoriteProjectClass(String(it.projectId))}
                   `}
                    projectId={it.projectId}
                    isShowSideBar={showSideBar}
                    imgUrl={String(it.project.imageUrl)}
                    projectName={it.project.name}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <ButtonExit
          classLabel={showSideBar ? style.label_tab : style.label_tab_clossable}
          isShowSideBar={showSideBar}
          onLogOut={() => authService.logOut()}
        />
      </div>
    </div>
  );
};
