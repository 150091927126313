import React, { FC, useMemo } from "react";
import style from "./style.module.css";
import { ReactComponent as DeleteIcon } from "./../../../../assets/img/users/delete.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/img/users/edit.svg";
import { IUser } from "@/typing/interfaces/domain";
import userIcon from "./../../../../assets/img/users/user.svg";
import { userTypeConfig } from "@/modules/users/config/user-type.config";
import { UserRole } from "@/typing/enums/users-role.enum";
import { useSelector } from "react-redux";
import { sectAccount } from "@/store/account/selectors";
import { Link, useNavigate } from "react-router-dom";
import { userPositionConfig } from "../../config/user-position.config";

interface IProps {
	info: IUser;
	removeUser: (val: { isShow: boolean; id: number }) => void;
	editUser: any;
}

export const UserTableRow: FC<IProps> = ({ info, removeUser, editUser }) => {
	const { data: account, isLoading } = useSelector(sectAccount);
	const navigate = useNavigate();

	return (
		<div
			key={info.id}
			onClick={() => navigate(`/users/profile/${info.id}`)}
			className={style.container}>
			<div className={style.accountContent}>
				<div className={style.avatarContainer}>
					<img
						className={style.avatar}
						src={info.avatarUrl ? info.avatarUrl : userIcon}
						alt="User"
					/>
				</div>
				<h3 className={style.accountName}>
					{info.name} {info.lastName}
				</h3>
			</div>
			<p className={style.accountType}> {userTypeConfig[info.role]}</p>
			<p className={style.accountEmail}>{info.email}</p>
			<p className={style.accountType}>
				{userPositionConfig[info?.positions?.[0]]}
			</p>
			<p className={style.accountType}>{info.cooperationStartDate}</p>
			<p className={style.accountType}>{info.birthDate}</p>
			{account.role === UserRole.Admin ? (
				<div className={style.accountAction}>
					<EditIcon
						className={style.actionIcon}
						onClick={(e) => {
							e.stopPropagation();
							editUser(info);
						}}
					/>
					<DeleteIcon
						className={style.actionIcon}
						onClick={(e) => {
							e.stopPropagation();
							removeUser({ isShow: true, id: info.id });
						}}
					/>
				</div>
			) : null}
		</div>
	);
};
