import React, { FC, useEffect, useState } from "react";
import style from "./style.module.css";
import { BaseDrawer } from "../../../../core/components/drawers/base-drawer";
import { Textarea } from "../../../../core/components/form/textarea-box/index";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemaForm } from "../../valid-schema/validation-schema-form";
import { IUser } from "@/typing/interfaces/domain";
import { Select, Tag } from "antd";
import { getUsersReq } from "@/api/users";
import { usePaginationList } from "@/core/hooks";
import { sectAccount } from "@/store/account/selectors";
import { useSelector } from "react-redux";
import { IProjectSubmitForm } from "../../interface/project-submit-form.interface";
import { InputFormBox } from "@/core/components/form/input-form-box";
import { SelectControlTags } from "@/core/components/form/select-control-tags";
import { PrimaryButtonForm } from "@/core/components/buttons/primary-button-form";
import { SecondaryButtonForm } from "@/core/components/buttons/secondary-button-form";
import { SelectControl } from "@/core/components/form/select-contol";
import { UploadImage } from "@/core/components";
import { IFile } from "@/typing";
import { setTimeout } from "timers";
import { async } from "validate.js";

const { Option } = Select;

interface IProps {
	titleDrawer: string;
	openDrawer: boolean;
	modeForm: string;
	onClose: () => void;
	onSaveProject: any;
	onSaveEdit: any;
	dataEdit?: any;
}

export const ProjectForm: FC<IProps> = ({
	titleDrawer,
	openDrawer,
	modeForm,
	onClose,
	onSaveProject,
	onSaveEdit,
	dataEdit,
}) => {
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors, isValid },
	} = useForm<IProjectSubmitForm>({
		mode: "onChange",
		resolver: yupResolver(validationSchemaForm),
		values: dataEdit,
	});

	const { data: account } = useSelector(sectAccount);
	const userList = usePaginationList<IUser>({
		fetchItems: getUsersReq,
		needInit: true,
		limit: 999,
		clearWhenReload: false,
		loadParams: {
			sortField: "id",
			sort: "DESC",
		},
	});

	useEffect(() => {
		if (!openDrawer) {
			reset({
				name: null,
				technologies: null,
				description: null,
				options: null,
				manager: null,
				developers: [],
				testing: [],
				imageUrl: null,
			});
		}
	}, [openDrawer]);

	const onSubmit = (data: IProjectSubmitForm) => {
		if (modeForm === "create") {
			onSaveProject(data);
		} else {
			onSaveEdit(data);
		}
		reset();
		onClose();
	};

	const renderUsers = (options: IUser[]) => {
		return options.map((it: IUser) => {
			return (
				<Option className="" key={it.id} label={`${it.name} ${it.lastName}`}>
					{`${it.name} ${it.lastName}`}
				</Option>
			);
		});
	};

	return (
		<BaseDrawer
			titleDrawerText={`${modeForm === "create" ? "Add new" : "Edit"} project`}
			titleDrawer={titleDrawer}
			openDrawer={openDrawer}
			onCloseDrawer={onClose}
			additionalStyles={{ paddingLeft: "20px" }}>
			<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
				<Controller
					control={control}
					name={"imageUrl"}
					render={({ field: { onChange, value } }) => (
						<UploadImage
							style={{ marginTop: 25, marginBottom: 25 }}
							onChange={(e) => onChange(e)}
							value={value}
						/>
					)}
				/>
				<div className={style.inner}>
					<InputFormBox
						placeholder="Enter project name"
						register={register}
						label="Title"
						keyField={"name"}
						error={errors.name?.message}
					/>
					<InputFormBox
						placeholder="Technologies or Languages"
						register={register}
						label="Technologies / Languages"
						keyField={"technologies"}
						error={errors.technologies?.message}
					/>

					<div>
						<p className={style.selectGroupTitle}>Manager</p>
						<SelectControl
							dataEdit={dataEdit?.manager}
							renderOptions={() => renderUsers(userList.items)}
							control={control}
							name="manager"
							register={register}
							defaultTitle="manager"
						/>
					</div>

					<div>
						<p className={style.selectGroupTitle}>Developers</p>
						<SelectControlTags
							placeholder="Select developers"
							control={control}
							mode="users"
							name="developers"
							option={userList?.items}
						/>
					</div>

					<div>
						<p className={style.selectGroupTitle}>Testing</p>
						<SelectControlTags
							placeholder="Select testing"
							control={control}
							mode="users"
							name="testing"
							option={userList?.items}
						/>
					</div>

					<Textarea
						placeholder="Write project description"
						register={register}
						label="Description"
						keyField={"description"}
						error={errors.description?.message}
					/>
					<InputFormBox
						placeholder="Write project option"
						register={register}
						label="Options"
						keyField={"options"}
						error={errors.options?.message}
					/>
				</div>
				<div className={style.buttonGroup}>
					<SecondaryButtonForm onClick={onClose} type="button" text="Cancel" />
					<PrimaryButtonForm
						type="submit"
						text={modeForm === "create" ? "Create" : "Save"}
						disabled={!isValid}
					/>
				</div>
			</form>
		</BaseDrawer>
	);
};
