import React, { FC } from 'react';
import style from './style.module.css';
import { Link, generatePath } from "react-router-dom";

import clockIcon from "@/assets/img/project/clock.svg";
import { ReactComponent as TrashIcon } from "@/assets/img/project/trash.svg";
import { ReactComponent as PencilIcon } from "@/assets/img/project/pencil.svg";
import { IProject } from "@/typing/interfaces/domain/project.interface";
import { UserRole } from "@/typing/enums/users-role.enum";
import { projectDetailedRouterName } from "../../config";
import { getTextPreview } from "@/core/helpers";

interface IProps {
	accountRole: string;
	info: IProject;
	removeProject: (e: React.MouseEvent, id: number) => void;
	editProject: (e: React.MouseEvent, id: IProject) => void;
}

export const ProjectCard: FC<IProps> = ({
	info,
	removeProject,
	editProject,
	accountRole,
}) => {
	const changeDateFormat = () => {
		const options: Intl.DateTimeFormatOptions = {
			day: "numeric",
			month: "long",
		};
		const date = new Date(info.createdAt);
		return date.toLocaleDateString("en-US", options);
	};

	const firstLetter = (str: string): string => {
		return str[0].toUpperCase();
	};

	const truncateString = (str: string): string => {
		return str.length > 100 ? str.slice(0, 85).trim() + "..." : str;
	};
	const pathDetailed = generatePath(projectDetailedRouterName, {
		id: String(info.id),
	});

	return (
		<Link className={style.project} to={pathDetailed} key={info.id}>
			<div className={style.projectTop}>
				<div className={style.projectTopBox}>
					<div>
						{info.imageUrl ? (
							<img
								className={style.projectTopImage}
								src={String(info.imageUrl)}
							/>
						) : (
							<p className={style.projectTopLetter}>{firstLetter(info.name)}</p>
						)}
					</div>
					<h5 className={style.projectTopTitle}>
						{getTextPreview(info.name, 20)}
					</h5>
				</div>
				{accountRole !== UserRole.User ? (
					<div className={style.projectButtons}>
						<button
							className={style.projectButtonsBtn}
							onClick={(e) => {
								e.stopPropagation();
								removeProject(e, info.id);
							}}>
							<TrashIcon className={style.projectIcon} />
						</button>
						<button
							className={style.projectButtonsBtn}
							onClick={(e) => {
								e.stopPropagation();
								editProject(e, info);
							}}>
							<PencilIcon className={style.projectIcon} />
						</button>
					</div>
				) : null}
			</div>

			<div className={style.projectContent}>
				<p className={style.projectContentTitle}>Description:</p>
				<p className={style.projectContentText}>
					{truncateString(info.description)}
				</p>
			</div>

			<div className={style.projectTopBox}>
				<p className={style.projectTopDate}>{changeDateFormat()}</p>
				<img src={clockIcon} alt="clock" />
			</div>
		</Link>
	);
};
