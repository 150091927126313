import { Tooltip } from "antd";
import React, { FC } from "react";
import style from "./style.module.css";
import { ReactComponent as GoOutLogo } from "./../../../../../../assets/img/left-side-bar/go-out.svg";

interface IProps {
  isShowSideBar: boolean;
  onLogOut: () => void;
  classLabel: string;
}

export const ButtonExit: FC<IProps> = ({
  isShowSideBar,
  onLogOut,
  classLabel,
}) => {
  return (
    <div className={`${style.wrapperContainerExit}`}>
      <button className={style.wrapperContent} onClick={onLogOut}>
        <Tooltip placement="right" title={"Exit"}>
          <div className={style.exitWrapper}>
            <GoOutLogo width={27} height={27} />
          </div>
        </Tooltip>
        <p className={`${classLabel} ${style.text}`}>Exit</p>
      </button>
    </div>
  );
};
