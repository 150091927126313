import { PrimaryButton, SecondaryButton } from '@/core/components'
import { PrimaryButtonForm } from '@/core/components/buttons/primary-button-form'
import { SecondaryButtonForm } from '@/core/components/buttons/secondary-button-form'
import { BaseDrawer } from '@/core/components/drawers/base-drawer'
import { InputBox } from '@/core/components/form/input-box'
import { InputFormBox } from '@/core/components/form/input-form-box'
import { Textarea } from '@/core/components/form/textarea-box'
import { ProjectTabsEnum } from '@/typing/enums/project-tabs.enum'
import { ITestingSection } from '@/typing/interfaces/domain/testing-section.interface'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ITestingFormSection } from '../../interface/testing-form-section.interface'
import { validationSchemaTestingSection } from '../../valid-schema/validation-schema-testing-title'
import style from './style.module.css'

interface IProps {
  titleDrawer: string
  openDrawer: boolean
  modeForm: string
  onClose: () => void
  dataEdit?: any
  onSaveSection?: (test: ITestingSection) => void
  onSaveEdit?: (test: ITestingSection) => void
  onChangeKey?: (key: ProjectTabsEnum) => void
}

export const TestingSectionForm = ({
  onSaveSection,
  onSaveEdit,
  titleDrawer,
  openDrawer,
  modeForm,
  onClose,
  dataEdit,
}: IProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ITestingFormSection>({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaTestingSection),
    values: { ...dataEdit },
    defaultValues: {
      name: '',
      description: ''
    },
  })

  const onSubmit = (data: ITestingSection) => {
    if (modeForm === 'create') {
      onSaveSection(data)
    } else if (modeForm === 'edit') {
      onSaveEdit(data)
    }
    onClose()
    reset()
  }

  useEffect(() => {
    reset()
  }, [openDrawer])

  return (
    <BaseDrawer titleDrawerText={modeForm === 'create' ? "Create new section" : "Edit section"} titleDrawer={titleDrawer} openDrawer={openDrawer} onCloseDrawer={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <InputFormBox label='Title' placeholder="Write section name" keyField={'name'} register={register} />
          <Textarea
            label="Description (optional)"
            placeholder="Write Description"
            keyField={'description'}
            register={register}
          />
          <div className={style.buttonGroup}>
            
            <SecondaryButtonForm onClick={onClose} text="Cancel" />
            <PrimaryButtonForm
              type="submit"
              text={modeForm === 'create' ? 'Create' : 'Save'}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </BaseDrawer>
  )
}
