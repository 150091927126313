import React, { FC } from "react";
import { IProjectBlock, ProjectTabsEnum } from "@/typing";
import { useSelector } from "react-redux";
import { getViewLookBlock } from "@/store/shared/selectors";
import { ContentBlockContainer } from "../../components/content-block-container";
import { RecordContentsModalSmart } from "../record-contents-modal";

interface IProps {
	addBlock: boolean;
	setAddBlock: (data: boolean) => void;
	onChangeKey: (key: ProjectTabsEnum) => void;
	reload: () => void;
	projectBlocks: IProjectBlock[];
}

export const ContentBlockViewSmart: FC<IProps> = ({
	addBlock,
	setAddBlock,
	onChangeKey,
	projectBlocks,
	reload,
}) => {
	const viewLookBlock = useSelector(getViewLookBlock);

	return (
		<>
			<ContentBlockContainer
				addBlock={addBlock}
				setAddBlock={setAddBlock}
				onChangeKey={onChangeKey}
				projectBlocks={projectBlocks}
				reload={reload}
				viewLookBlock={viewLookBlock}
			/>
			<RecordContentsModalSmart />
		</>
	);
};
