import { PageWrapper } from "@/core/components/page/page-wrapper";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { UserTableRow } from "../../components/user-table-row";
import { IUser } from "@/typing/interfaces/domain";
import { ConfigProvider, Pagination } from "antd";
import { message } from "antd";
import { deleteUsersReq, getUsersReq, patchUsersReq } from "@/api/users";
import { EmptyList } from "@/core/components/empty-list";
import { usePaginationList } from "@/core/hooks";
import { useSelector } from "react-redux";
import { sectAccount } from "@/store/account/selectors";
import _ from "lodash";
import { UserRole } from "@/typing/enums/users-role.enum";
import { ConfirmPopup } from "@/core/components/pop-up/confirm-popup";
import { PaginationArrow, SearchInput } from "@/core/components";
import { AccountForm } from "@/modules/account/components";

export const UsersPage: React.FC = () => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [popupDelete, setPopupDelete] = useState({ isShow: false, id: null });
	const [dataEdit, setDataEdit] = useState<IUser>(null);
	const [modeEditOrCreate, setModeEditOrCreate] = useState("");
	const [messageApi, contextHolder] = message.useMessage();
	const [searchValue, setSearchValue] = React.useState("");
	const { data: account, isLoading } = useSelector(sectAccount);



	const list = usePaginationList<IUser>({
		fetchItems: getUsersReq,
		needInit: true,
		limit: 10,
		clearWhenReload: false,
		loadParams: {
			sortField: "id",
			sort: "DESC",
		},
	});

	const errorError = (error: string) => {
		messageApi.open({
			type: "error",
			content: `Error: ${error}`,
		});
	};

	useEffect(() => {
		list.setLoadParams({
			searchString: searchValue,
		});
	}, [searchValue]);

	const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const onRemoveUser = async () => {
		try {
			const userId = list.items.find((el: IUser) => el.id === popupDelete.id);
			await deleteUsersReq(userId);
			list.setLoadParams({});
		} catch (error) {
			errorError(error.response.data.message);
		}
		setPopupDelete({
			isShow: false,
			id: null,
		});
	};

	const onCloseDrawer = () => {
		setOpenDrawer(false);
		setDataEdit(null);
	};

	const editUser = async (newData: IUser) => {
		try {
			await patchUsersReq(newData);
			list.setLoadParams({});
		} catch (error) {
			errorError(error.response.data.message);
		}
	};

	const preparationEditingUser = (info: any) => {
		setOpenDrawer(true);
		setDataEdit(info);
		setModeEditOrCreate("edit");
	};

	const removeUserId = (arr: IUser[], id: number) => {
		const el = arr.findIndex((el: IUser) => el.id === id);
		if (el > -1) {
			arr.splice(el, 1);
		}
		return arr;
	};

	useEffect(() => {
		removeUserId(list.items, account.id);
	}, [list.items]);

	return (
		<PageWrapper textPlaceholder={"Search user"} blockSearch={true}>
			{contextHolder}
			<AccountForm
				titleDrawerText={"Edit information about user"}
				titleDrawer={"Edit"}
				openDrawer={openDrawer}
				mode="profile"
				onClose={onCloseDrawer}
				onSaveEdit={editUser}
				dataEdit={dataEdit}
			/>
			<SearchInput
				styles={{ margin: "24px 20px 32px 20px", width: "97%" }}
				value={searchValue}
				onChange={(val) => setSearchValue((prev) => (prev = val))}
			/>
			<div className={styles.wrapper}>
				<ul className={styles.categoryList}>
					{account.role === UserRole.Admin ? (
						<>
							<li style={{ width: "20.3%" }}>User Name</li>
							<li style={{ width: "10%" }}>Account type</li>
							<li style={{ width: "20%" }}>Email</li>
							<li style={{ width: "10%" }}>Position</li>
							<li style={{ width: "10%" }}>Start of cooperating</li>
							<li style={{ width: "10%" }}>Date of Birth</li>
							<li style={{ width: "7%" }}>Actions</li>
						</>
					) : (
						<>
							<li style={{ width: "20.5%" }}>User Name</li>
							<li style={{ width: "10%" }}>Account type</li>
							<li style={{ width: "20%" }}>Email</li>
							<li style={{ width: "10%" }}>Position</li>
							<li style={{ width: "10%" }}>Start of cooperating</li>
							<li style={{ width: "12%" }}>Date of Birth</li>
						</>
					)}
				</ul>
				<>
					{!_.isEmpty(list.items) && !list.isLoading ? (
						<div className={styles.usersTable}>
							{list.items.map((el: IUser) => {
								return (
									<UserTableRow
										info={el}
										key={el.id}
										removeUser={setPopupDelete}
										editUser={preparationEditingUser}
									/>
								);
							})}
						</div>
					) : (
						<EmptyList
							conditionLoader={!list.isLoading}
							errorText={"List is empty"}
						/>
					)}
					<ConfirmPopup
						title={`Are you sure
						Delete this user?`}
						popupActive={popupDelete}
						onClose={setPopupDelete}
						onConfirm={onRemoveUser}
					/>
				</>
			</div>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: "#686868",
					},
				}}>
				<div className={styles.paginationWrapper}>
					<Pagination
						current={list.loadParams.page}
						total={list.loadParams.count}
						pageSize={list.loadParams.limit}
						onChange={(page) => list.loadPage(page)}
						onShowSizeChange={(_, size: number) =>
							list.setLoadParams({ limit: size })
						}
						className={styles.pagination}
						showSizeChanger={true}
						itemRender={PaginationArrow}
					/>
				</div>
			</ConfigProvider>
		</PageWrapper>
	);
};
