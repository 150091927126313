import * as Yup from 'yup'

export const validationSchemaRegistration = Yup.object().shape({
  name: Yup.string()
    .required('First Name is required')
    .min(3, 'First Name must be at least 3 characters')
    .max(12, 'First Name must not exceed 12 characters')
    // .matches(/^[a-zA-Z0-9\s]*$/, 'Please enter valid name')
    .trim(),
  lastName: Yup.string()
    .required('Last Name is required')
    .min(3, 'Last name must be at least 3 characters')
    // .matches(/^[a-zA-Z0-9\s]*$/, 'Please enter valid name')
    .max(12, 'Last name must not exceed 12 characters')
    .trim(),
  email: Yup.string()
    .required('Email is required')
    .email('The email must be a valid email address.'),
  password: Yup.string()
    .required('No password provided.')
    .min(6, 'Password must be 6 or more characters')
    .max(20, 'Password Name must not exceed 20 characters')
    .matches(/^\S*$/, 'Whitespace is not allowed'),
  birthDate: Yup.string().required('Date of Birth is required'),
  cooperationStartDate: Yup.string().required('Date of Cooperation is required'),
  position: Yup.string().required('Position is required'),
})
