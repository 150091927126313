import { loginRoute } from "@/modules/auth/config";
import { selectNavGroup } from "@/store/navigation";
import { NavGroupKey } from "@/typing";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

export const PrivateRoutesWrapper = () => {
  const navKey = useSelector(selectNavGroup);
  return navKey === NavGroupKey.Private ? (
    <Outlet />
  ) : (
    <Navigate to={loginRoute} />
  );
};
