import { IUser, UserRole } from '@/typing'
import { CloseOutlined } from '@ant-design/icons'
import { Checkbox, ConfigProvider, Select, Tag } from 'antd'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import style from './style.module.css'

const { Option } = Select

interface ITextWithCheckbox {
  checked: boolean
  children: any
}

interface ISelectControlTags {
  control: any
  name: string
  option: any
  mode: string
  placeholder: string
}

const TextWithCheckbox = ({ checked, children }: ITextWithCheckbox) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#fff',
            colorIcon: '#0F833D',
            colorBorder: '#0F833D',
          },
        }}
      >
        <Checkbox checked={checked} />
      </ConfigProvider>
      {children}
    </div>
  )
}

export const SelectControlTags = ({ control, name, option, mode, placeholder }: ISelectControlTags) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          placeholder={placeholder}
          size="large"
          className="containerSelectController"
          bordered={false}
          showArrow={false}
          value={value}
          onChange={onChange}
          mode="multiple"
          menuItemSelectedIcon={false}
          tagRender={({ label, value, closable, onClose }) => {
            return (
              <Tag  closeIcon={<CloseOutlined style={{fontSize: '16px', marginTop: '3px'}}/>}  className={style.checkBoxTag} closable={closable} onClose={onClose}>
                {label}
              </Tag>
            )
          }}
        >
          {mode === 'users' ? (
            <>
              {option.map((it: IUser) => (
                <Option className="optionCheckBox" key={it.id} label={`${it.name} ${it.lastName}`}>
                  <TextWithCheckbox checked={value.includes(it.id.toString())}>
                    {`${it.name} ${it.lastName}`}
                  </TextWithCheckbox>
                </Option>
              ))}
            </>
          ) : (
            <>
              {option.map((it: any) => (
                <Option className="optionCheckBox" key={it.value}>
                  <TextWithCheckbox checked={value.includes(it.value)}>{it.label}</TextWithCheckbox>
                </Option>
              ))}
            </>
          )}
        </Select>
      )}
    />
  )
}