import React, { FC, useEffect, useState } from 'react'
import { BaseDrawer } from '@/core/components/drawers/base-drawer'
import style from './style.module.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaForm } from '../../../../modules/account/valid-schema/validation-schema-form'
import { ISubmitAccountForm } from '../../../../modules/account/interface/account.interface'
import { AccountTags } from '../../account-tags'
import { InputFormBox } from '@/core/components/form/input-form-box'
import { SecondaryButtonForm } from '@/core/components/buttons/secondary-button-form'
import { PrimaryButtonForm } from '@/core/components/buttons/primary-button-form'
import { SelectControl } from '@/core/components/form/select-contol'
import { Select } from 'antd'
import { optionsUserPosition, optionsUserRole } from '@/modules/users/config/options'

interface IProps {
  titleDrawer?: string
  openDrawer: boolean
  onClose: () => void
  onSaveEdit: any
  dataEdit: any
  tags?: string[]
  setTags?: (data: string[]) => void
  mode: 'profile' | 'account'
  titleDrawerText: string
}
const { Option } = Select

export const AccountForm: FC<IProps> = ({
  titleDrawer,
  openDrawer,
  onClose,
  onSaveEdit,
  dataEdit,
  tags,
  setTags,
  mode,
  titleDrawerText,
}) => {
  const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors, isValid },
	} = useForm<ISubmitAccountForm>({
		mode: "onChange",
		resolver: yupResolver(validationSchemaForm),
		values: {
			...dataEdit,
			positions: dataEdit?.positions?.[0],
			position: dataEdit?.positions?.[0],
		},
		defaultValues: {
			name: "",
			lastName: "",
			email: "",
			birthDate: "",
			cooperationStartDate: "",
			technologies: [],
			role: "",
			position: "",
			positions: "",
		},
	});

	useEffect(() => {
		reset();
	}, [openDrawer]);

	const onSubmit = (data: ISubmitAccountForm) => {
		if (mode === "profile") {
			onSaveEdit({ ...data, positions: [data.positions] });
		} else {
			onSaveEdit({ ...data, technologies: tags, positions: [data.position] });
		}

		onClose();
	};

	const renderOptions = (options: any) => {
		return options.map((it: any) => {
			return (
				<Option className={style.selectOptions} key={it.value} label={it.label}>
					{it.label}
				</Option>
			);
		});
	};

	return (
		<BaseDrawer
			titleDrawerText={titleDrawerText}
			openDrawer={openDrawer}
			onCloseDrawer={onClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.formWrapper}>
					<div className={style.inputWrapper}>
						<InputFormBox
							register={register}
							label="First name"
							keyField={"name"}
							error={errors.name?.message}
						/>
						<InputFormBox
							register={register}
							label="Last Name"
							keyField={"lastName"}
							error={errors.lastName?.message}
						/>
						<InputFormBox
							register={register}
							label="Email"
							keyField={"email"}
							error={errors.email?.message}
						/>
						<div className={style.inputGroup}>
							<div>
								<p className={style.selectTitle}>Account type</p>
								<SelectControl
									disabled={mode === "account"}
									name="role"
									renderOptions={() => renderOptions(optionsUserRole)}
									dataEdit={dataEdit}
									control={control}
									register={register}
								/>
							</div>
							<div>
								<p className={style.selectTitle}>Position</p>
								<SelectControl
									name={mode === "profile" ? "positions" : "position"}
									renderOptions={() => renderOptions(optionsUserPosition)}
									dataEdit={dataEdit}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className={style.inputGroup}>
							<InputFormBox
								register={register}
								label="Start of cooperation"
								keyField={"cooperationStartDate"}
								type="date"
								modeColor="black"
								modeDate="cooperation"
								error={errors.cooperationStartDate?.message}
							/>
							<InputFormBox
								readOnly={mode === "profile"}
								register={register}
								label="Date of Birth"
								keyField={"birthDate"}
								type="date"
								error={errors.birthDate?.message}
								modeColor="black"
							/>
						</div>
						{mode === "account" && (
							<AccountTags tags={tags} setTags={setTags} />
						)}
					</div>
				</div>
				<div className={style.buttonGroup}>
					<SecondaryButtonForm onClick={onClose} type="button" text="Cancel" />
					<PrimaryButtonForm type="submit" text={"Save"} disabled={!isValid} />
				</div>
			</form>
		</BaseDrawer>
	);
}
