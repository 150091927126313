import { ITestingSection } from '@/typing/interfaces/domain/testing-section.interface'
import { ITesting } from '@/typing/interfaces/domain/testing.interface'
import { List, Tag } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { testingPriorityConfig } from '../../config/testing-priority.config'
import { colorTagPriority, colorTextTagPriority } from '../../helpers/color-tag-priority'
import style from './style.module.css'


interface IProps {
  item: ITesting;
  onDetailTesting: () => void;
  onTestingData: (item: ITesting) => void;
  onSetSectionName: () => void;
  testNumber: number;
}

export const SectionTest = ({
  item,

  onDetailTesting,
  onTestingData,
  onSetSectionName,
  testNumber,
}: IProps) => {
  return (
    <div
      className={style.test}
      onClick={() => {
        onDetailTesting();
        onTestingData(item);
        onSetSectionName();
      }}
    >
      <div className={style.testContainer}>
        <div>Test №{testNumber}</div>
        <Tag
          style={{
            color: `${colorTextTagPriority(item.priorities)}`,
          }}
          bordered={false}
          className={style.testPriority}
          color={colorTagPriority(item.priorities)}
        >
          {testingPriorityConfig[item.priorities]}
        </Tag>
      </div>
    </div>
  );
};
