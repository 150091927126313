import React, { FC } from 'react'
import { UploadImage } from '../../../modules/account/components/upload-image'
import style from './style.module.css'
import { authService } from '@/services/domain/auth.services'
import { ReactComponent as EditIcon } from '@/assets/img/account/Edit.svg'
import { Space, Tag } from 'antd'
import { SecondaryButton } from '@/core/components'
import moment from 'moment'
import { IUser, UserRole } from '@/typing'
import { userRole } from '../../../modules/account/config'
import userIcon from './../../../assets/img/users/user.svg'
import { useSelector } from 'react-redux'
import { sectAccount } from '@/store/account/selectors'
import { userPositionConfig } from '@/modules/users/config/user-position.config'

interface IProps {
  account: IUser
  renderProjects?: () => void
  preparationEditingAccount: () => void
  mode: 'profile' | 'account'
  success?: (value: string) => void
}

export const AccountCard = ({
  preparationEditingAccount,
  account,
  renderProjects,
  mode,
  success,
}: IProps) => {
  const {
		id,
		technologies,
		name,
		lastName,
		role,
		cooperationStartDate,
		userProjects,
		createdAt,
		birthDate,
		email,
		avatarUrl,
		position = "a",
		positions,
	} = account;

	const { data: accountData } = useSelector(sectAccount);

	return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {mode === "account" ? (
          <UploadImage success={success} accountId={id} avatarUrl={avatarUrl} />
        ) : (
          <div className={style.containerImage}>
            <img
              className={style.images}
              src={account?.avatarUrl ? account?.avatarUrl : userIcon}
              alt="User"
            />
          </div>
        )}

        <ul className={style.list}>
          <li className={style.item}>
            <h4 className={style.itemName}>{`${name} ${lastName}`}</h4>
          </li>
          <li className={style.item}>
            <p className={style.itemText}>Position:</p>
            <p className={style.itemInfo}>
              {account?.positions
                ? userPositionConfig[account?.positions?.[0]]
                : "No position"}
            </p>
          </li>
          <li className={style.item}>
            <p className={style.itemText}>Email:</p>
            <p className={style.itemInfo}>{email}</p>
          </li>
          <li className={style.item}>
            <p className={style.itemText}>Account type:</p>
            <p className={style.itemInfo}>{userRole[role]}</p>
          </li>
          <li className={style.item}>
            <p className={style.itemText}>Date of birth :</p>
            <p className={style.itemInfo}>
              {moment(birthDate).format("DD.MM.YYYY")}
            </p>
          </li>

          <li className={style.item}>
            <p className={style.itemText}>Technologies:</p>
            <Space size={[0, 8]} wrap>
              {technologies?.map((tag) => {
                return (
                  <Tag
                    className={style.tag}
                    key={tag}
                    style={{ userSelect: "none", color: "#131212" }}
                  >
                    {tag}
                  </Tag>
                );
              })}
            </Space>
          </li>

          <li className={style.item}>
            <p className={style.itemText}>Date created:</p>
            <p className={style.itemInfo}>
              {moment(createdAt).format("DD.MM.YYYY")}
            </p>
          </li>
          <li className={style.item}>
            <p className={style.itemText}>Start of cooperation:</p>
            <p className={style.itemInfo}>
              {cooperationStartDate
                ? moment(cooperationStartDate).format("DD.MM.YYYY")
                : "01.01.2020"}
            </p>
          </li>
          {mode === "account" && (
            <>
              <li className={style.item}>
                <div className={style.buttonItem}>
                  <SecondaryButton
                    text="Change Password"
                    onClick={() => authService.changePassword()}
                  />
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
      {mode === "profile" && accountData.role === UserRole.Admin && (
        <button
          className={style.editTextBtn}
          onClick={preparationEditingAccount}
        >
          <EditIcon className={style.editIcon} />
        </button>
      )}
      {mode === "account" && (
        <button
          className={style.editTextBtn}
          onClick={preparationEditingAccount}
        >
          <EditIcon className={style.editIcon} />
        </button>
      )}
    </div>
  );
}
