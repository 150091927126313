import { ITestingSection } from './../../typing/interfaces/domain/testing-section.interface'
import { ITesting } from '@/typing/interfaces/domain/testing.interface'
import { ITestingSectionDelReq,  ITestingSectionGetReq } from './interfaces'
import { http } from '../http.service'

export const getTestSectionReq = (params: ITestingSectionGetReq) => {
  return http.get<ITestingSection[]>(`/test-case-sections`, { params });
}

export const postTestSectionReq = (params: ITestingSection) => {
  return http.post<ITestingSection>('/test-case-sections', { ...params })
}

export const patchTestSectionReq = (payload: ITestingSection) => {
  return http.patch<ITestingSection>(`/test-case-sections/${payload.id}`, { ...payload })
}

export const deleteTestSectionReq = (params: ITestingSectionDelReq) => {
  return http.delete(`/test-case-sections/${params.id}`, { params })
}



export const postTestReq = (params: ITesting) => {
  return http.post<ITesting>('/test-case', { ...params })
}

export const patchTestReq = (params: ITesting) => {
  return http.patch<ITesting>(`/test-case/${params.id}`, { ...params })
}

export const deleteTestReq = (params: ITestingSectionDelReq) => {
  return http.delete(`/test-case/${params.id}`, { params })
}
