import React, { useState } from "react";
import style from "./style.module.css";
import { SideBar } from "../side-bar";
import { StoreActionKey } from "@/typing";

interface IProps {
	children: any;
	onChangeSearchValue?: (val: string) => void;
	textPlaceholder?: string;
	blockSearch?: boolean;
	searchValue?: string;
}
export const PageWrapper = ({
  children,
  onChangeSearchValue,
  textPlaceholder,
  blockSearch,
  searchValue,
}: IProps) => {
  const isOpenSideBar = JSON.parse(
    localStorage.getItem(StoreActionKey.SIDE_BAR)
  );
  const [showSideBar, setShowSideBar] = useState<boolean>(
    Boolean(isOpenSideBar)
  );

  const toggleSideBar = () => {
    if (showSideBar) {
      setShowSideBar(false);
      localStorage.setItem(StoreActionKey.SIDE_BAR, "0");
    } else {
      setShowSideBar(true);
      localStorage.setItem(StoreActionKey.SIDE_BAR, "1");
    }
  };
  return (
    <div className={style.wrapper}>
      <div className={style.wrapperContent}>
        <SideBar showSideBar={showSideBar} onShowSideBar={toggleSideBar} />
        <div
          className={
            !showSideBar
              ? style.childrenContainer
              : `${style.childrenContainer} ${style.childrenContainerShow}`
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

