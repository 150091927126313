import React, { CSSProperties } from "react";
import style from "./style.module.css";

interface IProps {
	label?: string;
	error?: string;
	keyField?: string;
	type?: string;
	placeholder?: string;
	register?: any;
	styleLabel?: CSSProperties;
	styles?: CSSProperties;
}

export const Textarea = ({
	label,
	error,
	keyField,
	placeholder,
	type,
	register,
	styleLabel,
	styles,
}: IProps) => {
	return (
		<div className={style.formGroup}>
			{label ? (
				<label style={styleLabel} className={style.inputLabel}>
					{label}
				</label>
			) : null}
			<textarea
				style={styles}
				type={type}
				placeholder={placeholder}
				{...register(keyField)}
				className={`${style.formControl} ${error ? `${style.isInvalid}` : ""}`}
			/>
			<div className={style.invalidFeedback}>{error}</div>
		</div>
	);
};
