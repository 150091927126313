import React, { FC, useEffect, useState } from 'react'
import style from './style.module.css'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaContentBlock } from '../../valid-schema/validation-schema-content-block'
import { IContentBlockForm } from '../../interface/content-block-form.interface'
import { BaseDrawer } from '@/core/components/drawers/base-drawer'
import { ProjectTabsEnum } from '@/typing/enums/project-tabs.enum'
import { InputFormBox } from '@/core/components/form/input-form-box'
import { SelectControlTags } from '@/core/components/form/select-control-tags'
import { optionsUserRole } from '@/modules/users/config/options'
import { PrimaryButtonForm } from '@/core/components/buttons/primary-button-form'
import { SecondaryButtonForm } from '@/core/components/buttons/secondary-button-form'

interface IProps {
  titleDrawer: string
  openDrawer: boolean
  modeForm: string
  onClose: () => void
  onSaveEdit: any
  dataEdit?: any
  onCreateBlock: any
  userList: any
  onChangeKey?: (key: ProjectTabsEnum) => void
}

export const ContentBlocksForm: FC<IProps> = ({
  titleDrawer,
  openDrawer,
  modeForm,
  onClose,
  onSaveEdit,
  dataEdit,
  onCreateBlock,
  userList,
  onChangeKey,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<IContentBlockForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaContentBlock),
    values: dataEdit,
  })

  useEffect(() => {
    if (!openDrawer) {
      reset({ name: null, allowedRoles: [], allowedUsersIds: [] })
    }
  }, [openDrawer])

  const onSubmit = (data: IContentBlockForm) => {
    if (modeForm === 'create') {
      onCreateBlock(data)
      onChangeKey(ProjectTabsEnum.Block)
    } else {
      onSaveEdit(data)
    }
    onClose()
    reset()
  }

  return (
    <BaseDrawer
      titleDrawerText={modeForm === 'edit' ? 'Edit block' : 'Add block'}
      titleDrawer={titleDrawer}
      openDrawer={openDrawer}
      onCloseDrawer={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <InputFormBox
            label="Title"
            placeholder="Write block name"
            keyField={'name'}
            register={register}
          />
          <div className={style.selectGroup}>
            <div className={style.selectContainer}>
              <p className={style.selectLabel}>Select allowed role</p>
              <SelectControlTags
                placeholder="Select allowed user"
                control={control}
                name="allowedRoles"
                option={optionsUserRole}
                mode={'roles'}
              />
            </div>
            <div className={style.selectContainer}>
              <p className={style.selectLabel}>Select allowed user</p>
              <SelectControlTags
                placeholder="Select allowed role"
                control={control}
                mode="users"
                name="allowedUsersIds"
                option={userList?.items}
              />
            </div>
          </div>

          <div className={style.buttonGroup}>
            <SecondaryButtonForm onClick={onClose} text="Cancel" />
            <PrimaryButtonForm
              type="submit"
              text={modeForm === 'create' ? 'Create' : 'Save'}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </BaseDrawer>
  )
}
