import { IProjectBlock, UserRole } from "@/typing";
import { Tag } from "antd";
import React from "react";
import { colorTagRole } from "../../helpers/color-tag-role";
import style from "./style.module.css";
import { ReactComponent as DeleteIcon } from "@/assets/img/project/trash.svg";
import { ReactComponent as EditIcon } from "@/assets/img/project/pencil.svg";
import { RootState } from "@/store";
import { useSelector } from "react-redux";

interface IProps {
	info: IProjectBlock;
	allowedRoles: string[];
	accountRole: string;
	editBlock: (e: any, value: IProjectBlock) => void;
	removeBlock: (e: any, value: number) => void;
	isModeViewBlock: boolean;
}

export const ContentBlockHeader = ({
	info,
	allowedRoles,
	accountRole,
	editBlock,
	removeBlock,
	isModeViewBlock,
}: IProps) => {
	return (
		<div className={style.titleWrapper}>
			<div className={style.roleWrapper}>
				<div className={style.titleName}>{info?.name}:</div>
				<div className={style.titleRole}>
					{isModeViewBlock && (
						<>
							{allowedRoles?.map((accountRole) => (
								<Tag
									className={style.tag}
									key={accountRole}
									color={colorTagRole(accountRole)}>
									{accountRole}
								</Tag>
							))}
						</>
					)}
				</div>
			</div>

			{isModeViewBlock && (
				<div className={style.actionButton}>
					{accountRole !== UserRole.User && (
						<>
							<EditIcon
								className={style.projectIcon}
								onClick={(e) => editBlock(e, info)}
							/>
							<DeleteIcon
								className={style.projectIcon}
								onClick={(e) => removeBlock(e, info?.id)}
							/>
						</>
					)}
				</div>
			)}
		</div>
	);
};
