import React from "react";
import styles from "./style.module.css";
import { ReactComponent as ColumnBoxIcon } from "@/assets/img/project/column-box.svg";
import { ReactComponent as WrapBoxIcon } from "@/assets/img/project/wrap-box.svg";
import { blockService } from "@/services/domain";
import { useSelector } from "react-redux";
import { getViewLookBlock } from "@/store/shared/selectors";

export const IconsViewBlockSmart = () => {
	const viewLookBlock = useSelector(getViewLookBlock);

	const onToggleViewBlock = async (viewLookBlock: "list" | "detailed") => {
		await blockService.toggleViewLookBlock(viewLookBlock);
	};

	return (
		<div className={styles.wrapperIcons}>
			<WrapBoxIcon
				onClick={() => onToggleViewBlock("list")}
				className={`${viewLookBlock === "list" ? styles.activeBlock : null}`}
			/>
			<ColumnBoxIcon
				onClick={() => onToggleViewBlock("detailed")}
				className={`${
					viewLookBlock === "detailed" ? styles.activeBlock : null
				} `}
			/>
		</div>
	);
};
